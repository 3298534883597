import axios, { Axios } from 'axios'
import { AuthStore } from '../../stores/auth.store'
import { AddTrackerRequest } from './user-profile.service'

export class UserProfileApi {
  BASE_URL = process.env.REACT_APP_BUSINESS_BASE_URL
  axiosInstance: Axios
  userInstance: Axios
  authStore: AuthStore
  constructor(authStore: AuthStore) {
    this.authStore = authStore
    this.axiosInstance = axios.create({
      baseURL: `${this.BASE_URL}/buy-tracker-bff`,
      headers: {
        Authorization: `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
    this.userInstance = axios.create({
      baseURL: `${this.BASE_URL}/user-bff`,
      headers: {
        Authorization: `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
  }

  async addTracker(request: AddTrackerRequest) {
    return await this.axiosInstance
      .post('add-tracker', request)
      .then((resp) => resp)
      .catch((err) => {
        throw err.response.status
      })
  }

  async getTrackers() {
    return await this.axiosInstance
      .get('')
      .then((resp) => resp)
      .catch((err) => {
        throw err.response.status
      })
  }

  async deleteTracker(trackerId: number) {
    return await this.axiosInstance
      .delete(`${trackerId}`)
      .then((resp) => resp)
      .catch((err) => {
        throw err.response.status
      })
  }

  async getUser(userId: string) {
    return await this.userInstance
      .get(userId)
      .then((resp) => resp)
      .catch((err) => {
        throw err.response.status
      })
  }
}
