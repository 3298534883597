import { makeAutoObservable } from 'mobx'
import { ShowInterestRequest } from '../../components/show-interest-modal/show-interest-modal.type'
import { EstateDetailsApi } from './estate-details.api'

/*
brokerName: "Svenska Fastighetsbyrån"
description: "-- EXKLUSIV VIND MED STOR TERRASS I SYD -- ÖPPEN PLANLÖSNING -- TOPPLÄGE PÅ KUNGSHOLMEN --"
estateType: " Bostadsrättslägenhet"
imageUrl: "https://si.sfcdn.se/Resurs/Image/316131/Large/637654831990000000/MDIyNnwwMDAxMjA2MDUyNXw5Ng...jpg"
location: {type: "Point", coordinates: [18.0330268, 59.3360071]}
numberOfRooms: 2
siteTitle: "Sankt Eriksgatan 55A, vån 5, Stockholm | Svensk Fastighetsförmedling"
sqrmLiving: 57
status: "pre_sale"
streetAddress: "Sankt Eriksgatan 55A"
town: "Stockholm"
url: "https://www.svenskfast.se/bostadsratt/stockholm/stockholm/stockholm/kungsholmen/sankt-eriksgatan-55a/316131"
__v: 0
_id: "6160a269924d27702377590b"
*/

export interface Estate {
  streetAddress: string
  town: string
  brokerName: string
  description: string
  estateType: string
  imageUrl: string
  images: string[]
  //location: { type: "Point"; coordinates: [18.0330268, 59.3360071] };
  numberOfRooms: number
  siteTitle: string
  sqrmLiving: number
  status: string
  url: string
  _id: string
  price?: number
  finalPrice?: number
  monthlyFee?: number
  builtAt?: number
  numberOfLevelsInBuilding?: number
  levelInBuilding?: number
}

export const mockEstate: Estate = {
  _id: 'mockId',
  brokerName: 'Svenska Fastighetsbyrån',
  builtAt: 1945,
  description:
    'Vi har nöjet att presentera denna utsökt renoverade gavel 4:a om 77 m² med ett centralt och eftertraktat läge på lugn återvändsgata intill grönskande natur.',
  estateType: ' Bostadsrättslägenhet',
  images: ['/mockImages/prop-1.jpg', '/mockImages/f4_1.jpg'],
  imageUrl: '/mockImages/prop-1.jpg',
  //location: { type: 'Point', coordinates: [18.1286551, 59.36289629999999] },
  numberOfRooms: 4,
  siteTitle: 'Björnvägen 14, vån 1, Lidingö | Svensk Fastighetsförmedling',
  sqrmLiving: 77,
  status: 'sale',
  streetAddress: 'Björnvägen 14',
  price: 3800000,
  town: 'Lidingö',
  url: 'https://www.svenskfast.se/bostadsratt/stockholm/lidingo/lidingo/herserud-torsvik/bjornvagen-14/321091',
}

export class EstateDetailsService {
  estateDetailsApi: EstateDetailsApi
  estates: Estate[]
  constructor() {
    makeAutoObservable(this)
    this.estateDetailsApi = new EstateDetailsApi()
    this.estates = []
  }

  async getEstates(objectIDs: string[]) {
    if (objectIDs.includes('mockId')) {
      let e = []
      for (let i = 0; i < objectIDs.length; i++) {
        e.push(mockEstate)
      }
      this.estates = e
      await new Promise((resolve) => setTimeout(resolve, 1000))
    } else {
      await this.estateDetailsApi
        .getEstates(objectIDs)
        .then((estates) => (this.estates = estates))
    }
  }

  async getEstate(objectId: string) {
    if (objectId === 'mockId') {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      this.estates = [mockEstate]
    }
    await this.estateDetailsApi
      .getEstates([objectId])
      .then((estates) => (this.estates = estates))
  }

  async showInterest(requestObejct: ShowInterestRequest) {
    await this.estateDetailsApi
      .showInterest(requestObejct)
      .then((response) => console.log('success: show interest'))
      .catch((err) => console.error('failed: show interest', err))
  }
}
