import axios, { Axios } from 'axios'
import { makeAutoObservable } from 'mobx'
import { AuthStore } from '../stores/auth.store'

export class SignUpApi {
  BASE_URL = process.env.REACT_APP_BUSINESS_BASE_URL
  axiosInstance: Axios
  authStore: AuthStore
  constructor(authStore: AuthStore) {
    this.authStore = authStore
    this.axiosInstance = axios.create({
      baseURL: `${this.BASE_URL}/sign-up-bff`,
      headers: { Authorization: `Bearer ${this.authStore.token}` },
    })
  }

  async sendInvite(email: string) {
    return await this.axiosInstance.post('invite', { email })
  }
}

export class SignUpService {
  api: SignUpApi
  constructor(authStore: AuthStore) {
    makeAutoObservable(this)
    this.api = new SignUpApi(authStore)
  }

  async inviteUser(email: string) {
    await this.api
      .sendInvite(email)
      .then((response) => console.log('success'))
      .catch((err) => {
        throw err
      })
  }
}
