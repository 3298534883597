import React from 'react'
import { isEnabled } from '../../environments/environment'
import { PrimaryButton } from '../buttons/primary-button/primary-button.component'
import { ShowInterestModal } from '../show-interest-modal/show-interest-modal.modal'
import './buttom-menu.scss'

export const ButtomMenu = ({
  originalUrl,
  sendInteresetRequest,
}: {
  originalUrl: string
  sendInteresetRequest: Function
}) => {
  const isShowInterestEnabled = isEnabled('showInterest')
  const [isShowModal, setShowModal] = React.useState(false)
  const closeModal = () => {
    setShowModal(false)
  }
  return (
    <div className="button-row">
      <ShowInterestModal
        hideModal={!isShowModal}
        closeModal={closeModal}
        sendInteresetRequest={sendInteresetRequest}
      />
      {isShowInterestEnabled && (
        <PrimaryButton
          text="Visa intresse"
          color={'green'}
          onClick={() => {
            setShowModal(true)
          }}
        />
      )}
      <a href={originalUrl} target="_blank" rel="noopener noreferrer">
        <PrimaryButton
          text="Till mäklaren"
          color={'inversed-green'}
          onClick={() => {}}
        />
      </a>
    </div>
  )
}
