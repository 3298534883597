import { PageWrapper } from '../page-wrapper/page-wrapper'
import './about-us.page.scss'
export const AboutUs = () => (
  <PageWrapper>
    <>
      <div className="about-us-content">
        <h1 className="title rubik-bold-white-40px">Om Hemla</h1>

        <div className="karla-normal-white-18px">
          Hemla drivs av en tydlig vision - vi vill bidra till en bättre
          bostadsmarknad. Konkret innebär det att vi vill tillföra ökad
          transparens och effektivitet till marknaden.
        </div>
        <div className="karla-normal-white-18px">
          <h4>Transparens</h4>
          <br />
          Idag är utbudet av bostäder spretigare än någonsin tidigare då mer än
          50% av bostäderna förannonseras utanför Hemnet. Därmed ställs allt
          högre krav på de konsumenter som vill hänga med i marknaden.
          <br />
          Genom att samla och tillgängliggöra relevant information vill vi
          underlätta beslutsfattandet och därmed spara tid och pengar för både
          köpare och säljare.
        </div>
        <div className="karla-normal-white-18px">
          <h4>Effektivitet</h4>
          <br />
          Vi tror att fler affärer kan genomföras på marknaden om bara köpare
          och säljare ges möjlighet att skräddarsy lösningar utefter deras
          önskemål.
          <br />
          Hemla förser även mäklarna med verktyg som matchar köpare & säljare på
          marknaden samtidigt som de får bättre möjligheter att tillgodose
          kundernas faktiska behov.
        </div>
      </div>
      <div className="about-us-husmodal">
        <PinkMono />
      </div>
    </>
  </PageWrapper>
)

export const PinkMono = () => (
  <svg
    width="1312"
    height="498"
    viewBox="0 0 1312 498"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1312 498L1210 103.035L2.17683e-05 3.68581e-06L0 498L1312 498Z"
      fill="#DF84AD"
    />
  </svg>
)
