import { makeAutoObservable } from 'mobx'
import { AuthStore } from '../../stores/auth.store'
import { EstateListingsApi } from './estate-listings.api'

export interface Recommendation {
  id: string
  estateId: string
  objectId: string
}

export interface RecommendationsList {
  list: Recommendation[]
  listInfo: { id: string; trackerId: string; name: string }[]
}

export class EstateListingsService {
  currentTrackerId: string | undefined = undefined
  recommendations?: RecommendationsList
  api: EstateListingsApi
  constructor(authStore: AuthStore) {
    makeAutoObservable(this)
    this.recommendations = undefined
    this.api = new EstateListingsApi(authStore)
  }

  async getLatestRecommendations(trackerId: string) {
    if (trackerId === 'mockId') {
      this.recommendations = {
        list: [
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
          { id: '1', estateId: 'estateId', objectId: 'mockId' },
        ],
        listInfo: [
          {
            id: 'mockId',
            name: 'mock match',
            trackerId: 'mockId',
          },
        ],
      }
      await new Promise((resolve) => setTimeout(resolve, 300))
    } else {
      if (!this.currentTrackerId || this.currentTrackerId !== trackerId) {
        this.currentTrackerId = trackerId
        await this.api
          ?.getLatestRecommendations(trackerId)
          .then((response) => (this.recommendations = response))
          .catch(async (err) => {
            console.error(err)
            await new Promise((resolve) => setTimeout(resolve, 100))
            await this.api
              ?.getLatestRecommendations(trackerId)
              .then((response) => (this.recommendations = response))
          })
      }
    }
  }
}
