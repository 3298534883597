import { makeAutoObservable } from 'mobx'
import { AgentSignUpRequest, AuthApi, EmailQueueType } from '../../api/auth.api'
import { AuthStore } from '../../stores/auth.store'

export class LoginService {
  private api: AuthApi
  authStore: AuthStore
  errorText: string = ''
  constructor(authStore: AuthStore) {
    makeAutoObservable(this)
    this.api = new AuthApi()
    this.authStore = authStore
  }

  async login(email: string, password: string) {
    return await this.api
      .login(email, password)
      .then((data) => {
        const token = data?.access_token as string
        const userId = data?.userId as number
        const role = data?.role as string
        this.authStore.storeToken(token, email, userId, role)
      })
      .catch((err) => {
        throw err
      })
  }

  logout() {
    this.authStore.resetAuth()
  }

  async register(userEmail: string, password: string, passwordRepeat: string) {
    return await this.api.register({
      email: userEmail,
      password,
      passwordRepeat,
    })
  }

  async signupToEmailQueue(email: string, queueType: EmailQueueType) {
    return await this.api.signupToEmailQueue(email, queueType)
  }

  async agentSignup(request: AgentSignUpRequest) {
    return await this.api.agentSignUp(request)
  }
}
