import React, { createContext, ReactNode, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { AdminPage } from './apps/admin-portal/admin-page/admin.page'
import { AgentMainPage } from './apps/agent-portal/agent-main.page'
import { isEnabled } from './environments/environment'
import './index.scss'
import { PageNotFound } from './pages/404-page/404.page'
import { AboutUs } from './pages/about-us/about-us.page'
import { AgentSignUp } from './pages/agent-pages/sign-up/agent-sign-up.page'
import { BuyPage } from './pages/buy-page/buy.page.responsive'
import { ContactUs } from './pages/contact-page/contact-us.page'
import { EmailSignUp } from './pages/email-sign-up/email-sign-up.page'
import { EstateDetails } from './pages/estate-details/estate-details.page'
import { EstateMatchPage } from './pages/estate-match/estate-match.page'
import { FaqPage } from './pages/faq-page/faq.page'
import { HomePageResponsive } from './pages/home/home.page.responsive'
import { PolicyPage } from './pages/policy/policy.page'
import { RegisterOnInvite } from './pages/register-on-invite/register-on-invite.page'
import { SellInfoAgents } from './pages/sell-info-agents/sell-info-agents.page'
import { SellPage } from './pages/sell-page/sell.page'
import { SignIn } from './pages/sign-in/sign-in.page'
import { UserProfile } from './pages/user-profile/user-profile.page'
import reportWebVitals from './reportWebVitals'
import { RootStore } from './stores/root.store'

// holds a reference to the store (singleton)
let store: RootStore = new RootStore()

// create the context
export const StoreContext = createContext<RootStore | undefined>(undefined)

// create the provider component
function RootStoreProvider({ children }: { children: ReactNode }) {
  //only create the store once ( store is a singleton)
  const root = store ?? new RootStore()

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>
}

function PrivateRoute({
  component: Component,
  authed,
  exact = false,
  ...rest
}: {
  component: any
  authed: boolean
  path: string
  exact?: boolean
}) {
  const [enabled] = useState(isEnabled('homepage'))
  if (enabled && rest.path === '/') {
    return (
      <Route {...rest}>
        <HomePageResponsive />
      </Route>
    )
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

function LogoutRoute() {
  if (store.authStore.isAuthed) {
    store.authStore.resetAuth()
  }
  return <Redirect to="/login" />
}

ReactDOM.render(
  <React.StrictMode>
    <RootStoreProvider>
      <Router>
        <Switch>
          <PrivateRoute
            authed={store.authStore.isAuthed}
            path="/match/:trackerId"
            component={EstateMatchPage}
          />

          <PrivateRoute
            authed={store.authStore.isAuthed}
            path="/details/:objectId"
            component={EstateDetails}
          />
          <PrivateRoute
            authed={store.authStore.isAuthed}
            path="/profile"
            component={UserProfile}
          />
          <Route path="/login">
            <SignIn />
          </Route>
          {/* <PrivateRoute
            exact={true}
            authed={store.authStore.isAuthed}
            component={HomePage}
            path="/"
          /> */}
          <PrivateRoute
            authed={store.authStore.isAuthed}
            component={BuyPage}
            path="/buy"
          ></PrivateRoute>
          <PrivateRoute
            authed={store.authStore.isAuthed}
            path="/sell"
            component={SellPage}
          ></PrivateRoute>
          <PrivateRoute
            authed={store.authStore.isAuthed}
            path="/admin"
            component={AdminPage}
          ></PrivateRoute>
          <PrivateRoute
            authed={store.authStore.isAuthed}
            path="/portal"
            component={AgentMainPage}
          ></PrivateRoute>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/faq">
            <FaqPage />
          </Route>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/email-sign-up">
            <EmailSignUp />
          </Route>
          <Route path="/for-agents">
            <SellInfoAgents />
          </Route>
          <Route path="/404">
            <PageNotFound />
          </Route>
          <Route path="/logout">
            <LogoutRoute />
          </Route>
          <Route path="/register-on-invite">
            <RegisterOnInvite />
          </Route>
          <Route path="/agent-sign-up">
            <AgentSignUp />
          </Route>
          <Route path="/policy">
            <PolicyPage />
          </Route>
          <PrivateRoute
            authed={store.authStore.isAuthed}
            path="/"
            component={HomePageResponsive}
          ></PrivateRoute>

          <Redirect to="/404" />
        </Switch>
      </Router>
    </RootStoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
