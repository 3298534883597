import axios, { Axios } from 'axios'

export type EmailQueueType = 'NotifyOnRelease'

export interface EmailQueueList {
  id: string
  email: string
  queue: EmailQueueType
  createdAt: Date
  removedAt: Date
}

export interface AgentSignUpRequest {
  firstName: string
  lastName: string
  email: string
  companyName: string
  phone?: string
}

export class AuthApi {
  BASE_URL = process.env.REACT_APP_BUSINESS_BASE_URL
  axiosInstance: Axios
  signUpBff: Axios
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${this.BASE_URL}/auth`,
    })
    this.signUpBff = axios.create({
      baseURL: `${this.BASE_URL}/sign-up-bff`,
    })
  }

  async login(email: string, password: string) {
    return await this.axiosInstance
      .post('login', { email, password })
      .then((response) => {
        if (response.status < 300) {
          return response.data
        }
        if (response.status > 300) {
          return response.statusText
        }
      })
      .catch((err) => {
        throw err.response.status
      })
  }

  async register(requestObejct: {
    email: string
    password: string
    passwordRepeat: string
  }) {
    return await this.signUpBff
      .post('register-on-invite', requestObejct)
      .catch((err) => {
        throw err.response.data
      })
  }

  async signupToEmailQueue(email: string, queue: EmailQueueType) {
    return await this.signUpBff
      .post('signup-email-queue', { email, queue })
      .catch((err) => {
        throw err.response.data
      })
  }

  async agentSignUp(request: AgentSignUpRequest) {
    return await this.signUpBff.post('signup-agent', request).catch((err) => {
      throw err.response.data
    })
  }
}
