import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CarouselList } from '../../components/carousel-list/carousel-list.component'
import { useRootStore } from '../../utils/useRootStore.hook'
import { Estate } from '../estate-details/estate-details.service'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './estate-match.page.scss'

export const EstateMatchPage = observer(() => {
  const rootStore = useRootStore()
  const { estateListingsService, estateDetailsService } = rootStore
  const { trackerId }: { trackerId: string } = useParams()
  const [trackerResultName, setTrackerResultName] = useState<string>()
  const [showSoldEstates, setShowSoldEstates] = useState(false)

  useEffect(() => {
    setShowSoldEstates(
      !!estateDetailsService.estates.find((estate) => estate.status === 'sold')
    )
  }, [estateDetailsService.estates])

  const filterEstates = (
    estates: Estate[],
    compareMethod: 'eq' | 'neq',
    filterByStatus: string
  ): Estate[] => {
    if (compareMethod === 'eq') {
      return estates.filter((estate) => estate.status === filterByStatus)
    }
    return estates.filter((estate) => estate.status !== filterByStatus)
  }

  useEffect(() => {
    if (trackerId) {
      //TODO move logic to store and store data during session
      estateListingsService.getLatestRecommendations(trackerId).then(() => {
        if (estateListingsService?.recommendations?.list?.length) {
          const { list, listInfo } = estateListingsService.recommendations
          setTrackerResultName(
            listInfo?.find((linfo) => linfo.trackerId === trackerId)?.name
          )
          const objectIDs = list.map(
            (recommendation) => recommendation.objectId
          )
          estateDetailsService.getEstates(objectIDs)
        }
      })
    }
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackerId])
  return (
    <PageWrapper>
      <div className="estate-match-page-container">
        <div className="estate-match-page">
          <div className="estate-match-page-description">
            <h2 className="dark">Mina bostadsmatchningar</h2>
            <p>
              Här ser du dina senaste matchningar. Observera att det kan
              förekomma skillnader mellan orginaldatat och den annons vi visar
              här.
            </p>
          </div>

          <div className="match-list-container">
            <h3 className="dark">{trackerResultName}</h3>
            <CarouselList
              estates={filterEstates(
                estateDetailsService.estates,
                'neq',
                'sold'
              )}
            />
            {/* <div className="reverse-row">
              <PrimaryButton
                text="Visa intresse på valda object"
                onClick={() => {}}
                color="orange"
              />
            </div> */}
          </div>
          {showSoldEstates && (
            <div className="match-list-container match-slim-list-container">
              <h3 className="dark">Nyligen sålda bostäder</h3>
              <CarouselList
                estates={filterEstates(
                  estateDetailsService.estates,
                  'eq',
                  'sold'
                )}
                settings={{ rows: 1 }}
              />
              {/* <div className="reverse-row">
              <PrimaryButton
                text="Visa intresse på valda object"
                onClick={() => {}}
                color="orange"
              />
            </div> */}
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  )
})
