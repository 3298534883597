import { useState } from 'react'
import { PrimaryButton } from '../../components/buttons/primary-button/primary-button.component'
import { useRootStore } from '../../utils/useRootStore.hook'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './email-sign-up.page.scss'

export const EmailSignUp = () => {
  const { loginService } = useRootStore()
  const [email, setEmail] = useState('')
  const [errorText, setErrorText] = useState<string | undefined>()

  const submitEmailSignup = () => {
    setErrorText(undefined)
    if (!email.includes('@')) {
      setErrorText('Skriv in din mailadress')
      return
    }
    loginService
      .signupToEmailQueue(email, 'NotifyOnRelease')
      .then((resp) => {
        setErrorText('Tack för intresseanmälan!')
      })
      .catch((err) => {
        if (err === 400) {
          setErrorText('Inkorrekt inmatning')
        } else {
          setErrorText('Fel uppstod')
        }
      })
  }

  return (
    <PageWrapper showCity={true}>
      <div className="buy-page">
        <div className="description-container">
          <h2 className="kpbevaka-med-hemla rubik-bold-watercourse-32px">
            Skriv upp dig på Hemlas intresselista
          </h2>
          <p className="karla-normal-shark-18px">
            Snart lanseras Hemlas unika köp- & säljbevakningar för förmarknaden.
            Skriv upp dig redan nu för att få information så fort tjänsten är
            live. Vi ser fram emot att hjälpa dig på bostadsresan!
          </p>
        </div>

        <div className="form-wrapper email-sign-up-form">
          <div className="form-row margin-bottom">
            <h4>Mailadress</h4>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-field"
              type="email"
              placeholder="Skriv in din mailadress"
            ></input>
          </div>
          {errorText && <p>{errorText}</p>}
          <div className="footer-row">
            <PrimaryButton
              text={'Slutför'}
              size="small"
              color="green"
              onClick={submitEmailSignup}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}
