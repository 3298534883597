import { makeAutoObservable } from 'mobx'
import { mockDelay } from '../../utils/mockDelay.util'

export interface Agent {
  id: string
  name: string
  firm: string
  imageUrl: string
  score?: {
    score: number
    votes: number
  }
}

export interface Lead {
  person: { name: string }
  type: string
  createdAt: Date
  status: string
}

export interface Assignments {
  person: { name: string }
  type: string
  createdAt: Date
  status: string
}

export class AgentPortalService {
  agent: Agent | undefined = undefined
  leads: Lead[] = []
  constructor() {
    makeAutoObservable(this)
  }

  async getAgentInformation(id: string) {
    this.agent = {
      name: 'Victor M Dannetun',
      firm: 'BOSTHLM',
      id: '1',
      imageUrl: '/home-page/ellipse-123@2x.png',
      score: {
        score: 7.8,
        votes: 54,
      },
    }
    await mockDelay()
    this.getLeads(this.agent.id)
  }

  async getLeads(id: string) {
    this.leads = [mockLead({}), mockLead({ name: 'Berra Bertilsson' })]
    await mockDelay()
  }
}

const mockLead = ({ name = 'Kalle Karlsson' }: { name?: string }): Lead => {
  return {
    person: { name },
    type: 'buy-lead',
    createdAt: new Date(),
    status: 'pending',
  }
}
