import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { PageWrapper } from '../../pages/page-wrapper/page-wrapper'
import { useRootStore } from '../../utils/useRootStore.hook'
import './agent-main.page.scss'
import { NavTabs } from './tabs/tabs.component'
import CollapsibleTable from './work-tabel/work-tabel.component'

export const AgentMainPage = observer(() => {
  const { agentPortalService } = useRootStore()
  const [activePageIndex, setActivePageIndex] = useState(0)
  const pages = [<OverviewPage />, <LeadsPage />, <AssignmentsPage />]

  useEffect(() => {
    agentPortalService.getAgentInformation('mockId')
  }, [agentPortalService])

  return (
    <PageWrapper>
      <div className="portal-page">
        <NavTabs
          setValue={setActivePageIndex}
          value={activePageIndex}
        ></NavTabs>
        {pages[activePageIndex]}
      </div>
    </PageWrapper>
  )
})

const OverviewPage = observer(() => {
  const { agentPortalService: store } = useRootStore()

  return (
    <div className="two-column-page">
      <div className="form-wrapper">
        <div className="row">
          <div className="column">
            <h3>Översikt</h3>
            <p>{store.agent?.name}</p>
            <p>{store.agent?.firm}</p>
            <img src={store.agent?.imageUrl} alt="profile" />
          </div>
          <div className="column">
            <h3>Betyg</h3>
            <p>
              {store.agent?.score?.score}/10 (av {store.agent?.score?.votes}
              röster)
            </p>
            <p>X leads</p>
            <p>X uppdrag</p>
            <p>X avslutade uppdrag</p>
          </div>
        </div>
      </div>

      <div className="form-wrapper">
        <h2>MAP</h2>
      </div>
    </div>
  )
})

const LeadsPage = observer(() => {
  const { agentPortalService: store } = useRootStore()
  return (
    <div className="two-column-page">
      <div>
        <div className="form-wrapper">
          <CollapsibleTable data={store.leads} />
        </div>
      </div>
      <div className="form-wrapper">
        <h2>MAP</h2>
      </div>
    </div>
  )
})

const AssignmentsPage = () => (
  <div className="two-column-page">
    <div>
      <div className="form-wrapper">
        <h2>Mina uppdrag</h2>
      </div>
    </div>
    <div className="form-wrapper">
      <h2>MAP</h2>
    </div>
  </div>
)
