import axios, { Axios } from 'axios'

export class GMapsApi {
  GMAPS_TOKEN = ''
  BASE_URL = `${process.env.REACT_APP_BUSINESS_BASE_URL}/buy-tracker-bff`
  axiosInstance: Axios
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: this.BASE_URL,
    })
  }

  async queryGMaps(search: string) {
    return await this.axiosInstance.get(`/search-hints?input=${search}`)
  }
}
