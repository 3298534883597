const clientFeatures = [
  { name: 'menu', default: 'true', isActive: 'true' },
  { name: 'buy-page', default: 'false', isActive: 'true' },
  { name: 'sell-page', default: 'false', isActive: 'true' },
  { name: 'homepage', default: 'true', isActive: 'true' },
  { name: 'homepage-extendContent', default: 'true', isActive: 'false' },
  { name: 'showInterest', default: 'false', isActive: 'false' },
]

const getActiveFeatures = (features: string[]) => {
  return clientFeatures.map((f) => {
    if (features.includes(f.name)) {
      return f
    }
    return undefined
  })
}

const localEnvironment = {
  name: 'development.local',
  features: getActiveFeatures([
    'menu',
    'buy-page',
    'sell-page',
    'showInterest',
  ]),
}

const devEnvironment = {
  name: 'development',
  features: getActiveFeatures(['menu', 'buy-page', 'sell-page']),
}

const productionEnvironment = {
  name: 'production',
  features: getActiveFeatures(['homepage']),
}

const envs = [localEnvironment, devEnvironment, productionEnvironment]
const getEnv = (env: string) => {
  return envs.find((e) => e.name === env)
}

export const environment = {
  ...getEnv(process.env.REACT_APP_ENV ?? 'production'),
}

export const isEnabled = (feature: string) => {
  const activeFeature = environment.features?.find((f) =>
    f?.name.includes(feature)
  )
  return activeFeature ? true : false
}
