import { PageWrapper } from '../page-wrapper/page-wrapper'
import './sell.page.scss'
export const SellPage = () => {
  return (
    <PageWrapper showCity={true}>
      <div className="buy-page">
        <div className="description-container">
          <h2 className="kpbevaka-med-hemla rubik-bold-watercourse-32px">
            Ta reda på intresset för din nuvarande bostad
          </h2>
          <p className="karla-normal-shark-18px">
            Om du äger din bostad kan du passa på att ta reda på intresset för
            din nuvarande bostad. Här beskriver vi alla fördelar med att
            säljbevaka med Hemla. Vi är oberoende och erbjuder det bästa
            verkyget på marknaden.
          </p>
        </div>

        <div className="form-wrapper sell-page-form">
          <p>Kommer snart</p>
        </div>
      </div>
    </PageWrapper>
  )
}
