import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { PinkMono } from '../about-us/about-us.page'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import { FaqContent } from './faq.content'
import './faq.page.scss'

export const FaqPage = () => (
  <PageWrapper>
    <>
      <div className="faq-page-content">
        <h1 className="title rubik-bold-white-40px">FAQ</h1>
        <div className="fag-page-description karla-normal-white-18px">
          Behöver du hjälp eller undrar du något? Då har du hittat rätt! Om du
          inte hittar svaret på din fråga är du varmt välkommen att kontakta
          oss.
        </div>

        <div className="faq-contact-card">
          <SimpleAccordion />
        </div>
      </div>
      <div className="faq-husmodul">
        <PinkMono />
      </div>
    </>
  </PageWrapper>
)

export default function SimpleAccordion() {
  return (
    <div>
      {FaqContent.map((item) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
          >
            <p className="rubik-bold-white-16px">{item.question}</p>
          </AccordionSummary>
          <AccordionDetails>
            <p>{item.answer}</p>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
