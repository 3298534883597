import { colors } from '../../utils/colors.util'
import './round.icon.scss'
export const IconRoundHouse = () => (
  <div className="circle-icon">
    <div className="circle-icon-backgrund">
      <svg
        width="35"
        height="35"
        viewBox="0 0 46 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.5218 20.2422H0V55.3392H45.5218V20.2422Z"
          fill={colors.white}
        />
        <path d="M0 19.5185H45.5218L22.7556 0L0 19.5185Z" fill={colors.white} />
      </svg>
    </div>
    <p className="karla-normal-shark-14px">Hus</p>
  </div>
)
