import axios, { Axios } from 'axios'
import { ShowInterestRequest } from '../../components/show-interest-modal/show-interest-modal.type'

export class EstateDetailsApi {
  private axiosInstance: Axios

  BASE_URL = process.env.REACT_APP_BUSINESS_BASE_URL
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${this.BASE_URL}/estate-bff`,
      timeout: 10000,
    })
  }

  async getEstates(objectIDs: string[]) {
    return await this.axiosInstance
      .post('', { objectIDs })
      .then((response) => response.data)
      .catch((err) => console.error(err))
  }

  async showInterest(requestObject: ShowInterestRequest) {
    return await this.axiosInstance
      .post('/show-interest', requestObject)
      .then((response) => response.data)
      .catch((err) => console.error(err))
  }
}
