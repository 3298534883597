import { colors } from '../../utils/colors.util'
import './round.icon.scss'
export const IconRoundCabin = () => (
  <div className="circle-icon">
    <div className="circle-icon-backgrund">
      <svg
        width="36"
        height="35"
        viewBox="0 0 46 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.6999 26.7812H0V59.706H42.6999V26.7812Z"
          fill={colors.white}
        />
        <path
          d="M0 26.0652H42.7106L21.35 7.75L0 26.0652Z"
          fill={colors.white}
        />
        <path
          d="M39.4939 11.707C39.4075 12.3281 39.1891 12.9237 38.8534 13.4536L43.4835 17.6553L42.4418 18.8009L37.8117 14.5993C37.326 15 36.7497 15.276 36.1328 15.4034L36.4299 21.5909L34.8814 21.6653L34.5844 15.4777C33.9676 15.3968 33.3771 15.1778 32.8568 14.8371L28.6505 19.463L27.5038 18.4224L31.6904 13.8181C31.3084 13.3132 31.0341 12.7354 30.8847 12.1203L24.691 12.4176L24.6167 10.8707L30.8104 10.5734C30.9044 9.95419 31.1222 9.3602 31.4508 8.82682L26.7392 4.6497L27.7809 3.50404L32.3894 7.68611C32.8852 7.30156 33.4576 7.02737 34.0682 6.88196L33.7712 0.694391L35.3196 0.620058L35.6167 6.80762C36.2449 6.8932 36.8477 7.11088 37.3855 7.44627L41.5721 2.84198L42.7188 3.88258L38.6178 8.54478C38.9811 9.05071 39.247 9.61981 39.4019 10.223L45.5956 9.92564L45.6698 11.4725L39.4939 11.707Z"
          fill={colors.white}
        />
      </svg>
    </div>
    <p className="karla-normal-shark-14px">Radhus</p>
  </div>
)
