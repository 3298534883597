import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Lead } from '../agent-protal.service'
import { ProfileIcon } from '../tabs/tabs.component'
import './work-tabel.component.scss'

function Row(props: { row: Lead }) {
  const { row } = props
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow
        className="tabel-row"
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <TableCell component="th" scope="row">
          <h4 className="white">{row.person.name}</h4>
        </TableCell>
        {/* <TableCell align="right">{row.remove}</TableCell>
        <TableCell align="right">{row.convert}</TableCell> */}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: '#ffffff' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className="tabel-row">
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          className="tabel-cell-open-container"
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <ProfileIcon /> {row.person.name}
              </Typography>
              <div className="row">
                <div className="column">
                  <a
                    className="karla-normal-shark-14px"
                    href="mailto:email@email.se"
                  >
                    email@email.se
                  </a>
                  <p className="karla-normal-shark-14px">076 123 23 12</p>
                </div>
                <div className="column">
                  <p className="karla-normal-shark-14px">
                    Söker bostad i Vasastan
                  </p>
                  <p className="karla-normal-shark-14px">
                    Behöver sälja bostad efter köp
                  </p>
                  <p className="karla-normal-shark-14px">
                    Vill flytta inom 3 månader
                  </p>
                  <p className="karla-normal-shark-14px">
                    Har lånlöft för 3,5 Mkr
                  </p>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function CollapsibleTable({ data }: { data: Lead[] }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          {/* <TableRow>
            <TableCell />
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow> */}
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row.person.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
