import './round-arrow-button.component.scss'
export const RoundArrowButton = ({
  className,
  onClick: onClickFn,
  reversed = false,
  inversed = false,
  width = 36,
  height = 37,
  colorTheme = 'green',
}: {
  onClick: Function
  reversed?: boolean
  inversed?: boolean
  width?: number
  height?: number
  colorTheme?: string
  className?: string
}) => {
  return (
    <button
      className={`borderless-button ${className}`}
      onClick={() => onClickFn()}
    >
      <svg
        className={`svg-theme-${colorTheme}`}
        width={width}
        height={height}
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform={reversed ? 'scale(-1,1)' : undefined}
      >
        {inversed ? (
          <path
            d="M1 18.4063C1 9.01741 8.61116 1.40626 18 1.40626C27.3888 1.40626 35 9.01742 35 18.4063C35 27.7951 27.3888 35.4062 18 35.4062C8.61116 35.4062 1 27.7951 1 18.4063Z"
            stroke-width="2"
          />
        ) : (
          <ellipse cx="18" cy="18.4063" rx="18" ry="18" />
        )}
        <path d="M14.8486 27.5425L13.5508 26.2536L21.4783 18.3858L13.5936 10.5546L14.8914 9.26562L24.0739 18.3858L14.8486 27.5425Z" />
      </svg>
    </button>
  )
}
