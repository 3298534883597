export enum AccessToPropertyTimeFrame {
  ASAP,
  WITHIN_3_MONTHS,
  WITHIN_6_MONTHS,
  FLEXIBLE,
}

export enum CustomerJourney {
  HAVE_SOLD_WANT_TO_BUY,
  WANT_TO_SELL_BEFORE_BUYING,
  WANT_TO_BUY,
}

export interface ShowInterestRequest {
  objectId: string;
  userId: number; //TODO: should be set in token
  fullName: string;
  currentAddress: string;
  currentZipCode: string;
  phone: string;
  canPlaceOfferBeforeShowing: boolean;
  customerJourney: CustomerJourney;
  wantedAccessWhen: AccessToPropertyTimeFrame;
}
