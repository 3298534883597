import { ContactUsCard } from '../../components/contact-us-card/contact-us-card.component'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './contact-us.page.scss'

export const ContactUs = () => {
  return (
    <PageWrapper>
      <>
        <div className="contact-us-page-content">
          <h1 className="title rubik-bold-white-40px">Kontakta oss</h1>
          <div className="behver-du-hjlp-un karla-normal-white-18px">
            Behöver du hjälp?&nbsp;&nbsp;Undrar du något? Eller vill du bara
            snacka? Hör av dig!
          </div>

          <div className="contact-us-contact-card">
            <ContactUsCard />
          </div>
        </div>
        <div className="contact-us-husmodul">
          <GreenMono />
        </div>
      </>
    </PageWrapper>
  )
}

const GreenMono = () => (
  <svg
    width="1312"
    height="498"
    viewBox="0 0 1312 498"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1312 498L1210 103.035L2.17683e-05 3.68581e-06L0 498L1312 498Z"
      fill="#0C725D"
    />
  </svg>
)
