import axios, { Axios } from 'axios'
import { AuthStore } from '../../stores/auth.store'
import { RecommendationsList } from './estate-listings.service'

const LATEST_RECOMMENDATIONS = '/lastest-recommendations'
export class EstateListingsApi {
  private axiosInstance: Axios
  private authStore: AuthStore

  BASE_URL = process.env.REACT_APP_BUSINESS_BASE_URL

  constructor(authStore: AuthStore) {
    this.authStore = authStore
    this.axiosInstance = axios.create({
      baseURL: `${this.BASE_URL}/buy-tracker-bff`,
      timeout: 1000,
      headers: { Authorization: `Bearer ${this.authStore.token}` },
    })
  }

  async getLatestRecommendations(userId: string) {
    return await this.axiosInstance
      .get(`${LATEST_RECOMMENDATIONS}/${userId}`)
      .then((response) => response.data as RecommendationsList)
      .catch((err) => {
        throw err.response?.status
      })
  }
}
