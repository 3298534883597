import '../../../color-theme.scss'
import './primary-button.component.scss'

export type buttonSize = 'small' | 'medium' | 'big'

//TODO remove this, don't make it to advanced. Use simple buttons and apply styling!
export const PrimaryButton = ({
  text,
  onClick: onClickFn,
  color = 'green',
  size = 'small',
  ...props
}: {
  text: string
  onClick: Function
  color?: string
  size?: buttonSize
  inversed?: boolean
  disabled?: boolean
}) => {
  let classes = ['primary-button']
  classes.push(`primary-button-${size}`)
  classes.push(`color-theme-${color}`)
  return (
    <button
      {...props}
      className={classes.join(' ')}
      onClick={() => onClickFn()}
    >
      {text}
    </button>
  )
}
