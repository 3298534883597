import { useEffect, useRef, useState } from 'react'
import './progress-bar.container.scss'

export const ProgressBar = ({
  step,
  steps,
}: {
  step: number
  steps: number
}) => {
  const ref = useRef<HTMLHeadingElement>(null)
  const [w, setW] = useState(400)
  useEffect(() => {
    setW(ref.current?.offsetWidth ?? 0)
  }, [])
  return (
    <div className="progress-bar" ref={ref}>
      <div className="background-bar"></div>
      <svg
        className="progress-marker"
        width={step * (w / steps)}
        height="11"
        viewBox="0 0 105 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5.55469C0 2.79326 2.23858 0.554688 5 0.554688H100C102.761 0.554688 105 2.79326 105 5.55469V5.55469C105 8.31611 102.761 10.5547 100 10.5547H5C2.23858 10.5547 0 8.31611 0 5.55469V5.55469Z"
          fill="#F6682F"
        />
      </svg>
    </div>
  )
}
