import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import './radio-button-group.component.scss'

export interface RadioButtonGroupOptions {
  value: string
  label: string
  isDefault?: boolean
}

export const RadioButtonsGroup = ({
  options,
  onChange: onChangeFn,
}: {
  options: RadioButtonGroupOptions[]
  onChange: Function
}) => {
  return (
    <FormControl>
      {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={
          options.find((option) => option.isDefault)?.value ?? options[0].value
        }
        name="radio-buttons-group"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChangeFn((event.target as HTMLInputElement).value)
        }
      >
        {options.map((option) => (
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
