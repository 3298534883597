export const FaqContent: { question: string; answer: string }[] = [
  {
    question: 'Vad är förmarknaden?',
    answer: `Bostadsmarknadens förmarknad utgörs av de bostäder som annonseras som kommande försäljningar. Olika mäklarfirmor har olika benämningar på dessa annonser och några exempel är: ”kommande”, ”snart till salu”, ”på gång”.`,
  },
  {
    question: 'Hur många bostäder finns på förmarknaden?',
    answer: `De senaste fem åren har antalet bostäder på förmarknaden ökat i snabb takt. Idag är över 50% av alla bostäder på marknaden annonserade som kommande försäljningar. Det innebär att det finns ett stort dolt utbud som är utspritt på mäklarnas hemsidor. Dessa hjälper vi till att synliggöra för konsumenter.`,
  },
  {
    question: 'Vad innebär kommande försäljningar?',
    answer: `Att annonsera en bostad som kommande försäljning innebär att mäklaren och säljare förbereder bostaden inför en stundande försäljning. Genom att förbereda en annons ges säljare möjlighet att snabbt dra igång en försäljningsprocess när de väl har hittat sin nya bostad. För mäklare finns det också fördelar med att annonsera en bostad som kommande eftersom att de i ett tidigt skede kan bli kontaktade av spekulanter.`,
  },
  {
    question: 'Är Hemlas tjänster gratis?',
    answer: `Ja, alla tjänster för köpare och säljare är gratis.`,
  },
  {
    question: 'Vad innebär off-market? ',
    answer: `Off-market är de bostäder som inte är på marknaden/förmarknaden idag men där säljaren kan tänka sig att sälja om han/hon erhåller ett bud som matchar dennes önskade pris. Detta är alltså ett utbud av potentiella bostäder till salu som varken finns på marknaden eller på förmarknaden idag.`,
  },
  {
    question: 'Hur hittar man rätt mäklare?',
    answer: `Rätt mäklare beror av flera olika faktorer som personkemi, förtroende, avtalsupplägg, kompetens, erfarenhet, marknadskännedom, etc. Vi kan tyvärr inte vara behjälpliga i alla dessa delar men vi bidrar i alla fall med att sålla fram de mäklare som, baserat på historisk data och statistik, har lyckats bäst med liknande bostäder i ditt område.`,
  },

  {
    question: 'Är jag bunden av mäklarofferterna?',
    answer: `Nej, ingen offert är bindande. Du har alltid möjlighet att tacka nej till ett mäklarerbjudande om det inte motsvarar dina förväntningar.`,
  },
  {
    question: 'Ska man köpa eller sälja först?',
    answer: `Den eviga frågan! Det man kan konstatera är i alla fall att trenden med förmarknad/ kommande försäljningar är ett försök att underlätta köp - och säljprocessen och tiden däremellan. Hemlas  köp- och säljbevakningar med tillkommande tjänster är framtagna för att erbjuda ytterligare flexibilitet på bostadsmarknaden.`,
  },
]
