import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../components/buttons/primary-button/primary-button.component'
import { UpdateForm } from '../../components/forms/update-form/update-form.component'
import { useRootStore } from '../../utils/useRootStore.hook'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './user-profile.page.scss'
import { Tracker, UserProfileService } from './user-profile.service'

const translation: any = {
  maxPrice: 'Max pris',
  minPrice: 'Min pris',
  minRoom: 'Min. rum',
  maxRoom: 'Max. rum',
  minSqrm: 'Min yta',
  maxSqrm: 'Max yta',
  interval: 'Interval av mejl',
  estateType: 'Typ',
  room: 'Antal Rum',
  sqrm: 'Yta',
  BUY: 'Köpbevakning',
  SELL: 'Säljbevakning',
}

export const UserProfile = observer(() => {
  const { authStore } = useRootStore()

  const [userProfileService] = React.useState(
    () => new UserProfileService(authStore)
  )

  const [trackers, setTrackers] = React.useState<Tracker[]>()

  useEffect(() => {
    getTrackers()
    // NOTE: Run effect once on component mount, please
    // recheck dependencies if effect is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.email])

  const getTrackers = () => {
    userProfileService.getTrackers().then((response) => setTrackers(response))
  }

  // const addTrackers = (request: AddTrackerRequest) => {
  //   userProfileService.addTracker(request).then((resp) => getTrackers())
  // }

  const deleteTracker = (id: number) => {
    userProfileService.deleteTracker(id).then((resp) => getTrackers())
  }

  return (
    <PageWrapper>
      <>
        <div className="left-side">
          <h2>Min bevakning</h2>
          <p>{authStore.email}</p>
          <div>
            <div className="content-container">
              <div>
                <h4>Bevakningar</h4>
                <div className="tracker-list"></div>
                {trackers?.map((t, i) => {
                  return (
                    <div className="tracker-list-item" key={t.trackerId}>
                      <h3>
                        {i}. {t.locations}
                      </h3>
                      <div>
                        {translation[t.trackerType]}
                        {t.requirements?.map((r) => (
                          <p key={r.id}>
                            {`${translation[r.property.name]}: ${r.value}`}
                          </p>
                        ))}
                      </div>
                      <button onClick={() => deleteTracker(+t.trackerId)}>
                        Ta bort
                      </button>
                      <Link to={`/match/${t.trackerId}`}>
                        <PrimaryButton
                          text="Se matchningar"
                          color={'inversed-green'}
                          onClick={() => {}}
                        />
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div>
          <UpdateForm />
        </div>
      </>
    </PageWrapper>
  )
})
