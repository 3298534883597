import { observer } from 'mobx-react-lite'
import React from 'react'
import { Estate } from '../../pages/estate-details/estate-details.service'
import { RoundArrowButton } from '../buttons/round-arrow-button/round-arrow-button.component'
import './image-slider.component.scss'
export const ImageSlider = observer(
  ({ estate }: { estate: Estate | undefined }) => {
    //images are actually just urls.
    const [images, setImages] = React.useState(estate?.images ?? [])
    const [activeImageUrl, setActiveImageUrl] = React.useState(images[0])
    React.useEffect(() => {
      if (estate?.images) {
        setImages(estate.images)
      }
    }, [estate])

    const plusSlides = (n: number) => {
      if (activeImageUrl && images) {
        const currentIndex = images.indexOf(activeImageUrl)
        const newActiveImageUrl =
          images.at((currentIndex + n) % images.length) ?? images[0]
        setActiveImageUrl(newActiveImageUrl)
      }
    }

    return (
      <div className="slideshow-container">
        {images.map((imageUrl) => {
          return (
            <div
              key={imageUrl}
              className={`mySlides fade ${
                imageUrl === activeImageUrl && 'active'
              }`}
            >
              <img src={imageUrl} alt="" />
            </div>
          )
        })}

        {/* <div
              key={estate.imageUrl}
              className={`mySlides fade ${"true" && "active"}`}
            >
              <img src={estate.imageUrl} alt="" />
            </div> */}

        {images.length > 1 && (
          <>
            <RoundArrowButton
              className="prev-v2"
              reversed={true}
              onClick={() => plusSlides(-1)}
            />
            <RoundArrowButton
              className="next-v2"
              onClick={() => plusSlides(1)}
            />
          </>
        )}
      </div>
    )
  }
)
