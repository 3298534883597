import { useState } from 'react'
import { Link } from 'react-router-dom'
import '../../components/buttons/primary-button/primary-button.component.scss'
import { ContactUsCard } from '../../components/contact-us-card/contact-us-card.component'
import { isEnabled } from '../../environments/environment'
import '../../styleguide.scss'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './home.page.responsive.scss'

export const HomePageResponsive = () => {
  const [displayBuyPage] = useState(isEnabled('buy-page'))
  const [displaySellPage] = useState(isEnabled('sell-page'))

  return (
    <>
      <PageWrapper wideContent={true}>
        <div className="home-container">
          <section id="home">
            <h1>Vi samlar alla bostäder på förmarknaden!</h1>
            <p>
              Idag är över hälften av alla bostäder till salu utanför den öppna
              marknaden. Ligg steget före och bevaka marknaden för kommande
              bostäder med Hemla.
            </p>
            <h3>Hur kan vi hjälpa dig?</h3>
            <div className="button-row">
              <Link to={displayBuyPage ? '/buy' : '/email-sign-up'}>
                <button className="primary-button-xl color-theme-orange">
                  Köpa bostad
                </button>
              </Link>
              <Link to={displaySellPage ? '/sell' : '/email-sign-up'}>
                <button className="primary-button-xl color-theme-green">
                  Sälja bostad
                </button>
              </Link>
            </div>
            <img
              alt="this is text"
              className="home-stad"
              src="home-page/animerad-stad-1@1x.png"
            />
          </section>
          <section id="buy-and-sell">
            <div id="buy-info">
              <div className="buy-and-sell-content-column">
                <img
                  alt="this is text"
                  className="header-img"
                  src="home-page/group-2198@2x.png"
                />
                <h2 className="rubik-bold-white-25px">Köpbevakning</h2>
                <ul>
                  <li>
                    <CheckMark />
                    <p>
                      Få skräddarsydda matchningar på kommande försäljningar
                    </p>
                  </li>
                  <li>
                    <CheckMark />
                    <p>Nå ut enkelt till alla mäklare på intressanta objekt</p>
                  </li>
                  <li>
                    <CheckMark />
                    <p>Få unik tillgång till bostäder off-market</p>
                  </li>
                </ul>
                <Link to="/email-sign-up">
                  <button
                    disabled={true}
                    className="primary-button-big color-theme-orange"
                  >
                    kommer snart
                  </button>
                </Link>
              </div>
            </div>
            <div id="sell-info">
              <div className="buy-and-sell-content-column">
                <img
                  alt="this is text"
                  className="header-img"
                  src="home-page/group-2239-1@2x.png"
                />
                <h2 className="rubik-bold-shark-25px">Säljbevakning</h2>
                <ul>
                  <li>
                    <CheckMarkGreen />
                    <p>Följ värdet och intresset på din bostad i realtid</p>
                  </li>
                  <li>
                    <CheckMarkGreen />
                    <p>Välj bland mäklare specifikt framtagna för din bostad</p>
                  </li>
                  <li>
                    <CheckMarkGreen />
                    <p>
                      Ange önskat pris och låt spekulanter matcha det off-market
                    </p>
                  </li>
                </ul>
                <Link to="/email-sign-up">
                  <button
                    disabled={true}
                    className="primary-button-big color-theme-pink"
                  >
                    kommer snart
                  </button>
                </Link>
              </div>
            </div>
          </section>
          <section id="contact">
            <ContactUsCard />
          </section>
        </div>
      </PageWrapper>
    </>
  )
}

const CheckMark = () => (
  <img
    alt="this is text"
    className="li-mark"
    src="home-page/bock-4@2x.png"
  ></img>
)

const CheckMarkGreen = () => (
  <img alt="this is text" className="li-mark" src="home-page/bock-7@2x.png" />
)
