import { PageWrapper } from '../page-wrapper/page-wrapper'
import './policy.page.scss'

export const PolicyPage = () => (
  <PageWrapper>
    <div className="policy-page">
      <h4>Vår policy</h4>
      <embed src="policy.pdf" width="100%" height="800px" />
    </div>
  </PageWrapper>
)
