import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { SignUpService } from '../../../api/sign-up.api'
import { PrimaryButton } from '../../../components/buttons/primary-button/primary-button.component'
import { PageWrapper } from '../../../pages/page-wrapper/page-wrapper'
import { useRootStore } from '../../../utils/useRootStore.hook'
import { Agent } from '../../agent-portal/agent.type'
import { SimpleTable } from '../simple-table.component'
import './admin.page.scss'

const getAgentValue = (row: Agent, p: [string, string]) => {
  let key = p[0] as keyof Agent
  return row[key]
}

export const AdminPage = observer(() => {
  const { authStore, adminService } = useRootStore()
  const signUpService = new SignUpService(authStore)
  const [invitationEmail, setInvitaionEmail] = useState('')

  useEffect(() => {
    adminService.getAgents({ status: 'SIGN_UP_REQUESTED' })
    adminService.getEmailQueueList({ removedAt: undefined })
  }, [adminService])

  const sendInvitaion = () => {
    signUpService
      .inviteUser(invitationEmail)
      .then(() => {
        setInvitaionEmail('')
      })
      .catch((err) => {
        alert(`Could not send invation to ${invitationEmail}`)
      })
  }

  if (authStore.role !== 'ADMIN') {
    return <Redirect to={{ pathname: '/login' }} />
  }

  return (
    <PageWrapper>
      <div className="admin-portal-root">
        <h1>Admin page</h1>

        <div className="form-wrapper">
          <div className="input-row">
            <h4>Bjud in användare (ej mäklare)</h4>
            <input
              type="email"
              className="text-field karla-normal-shark-20px"
              placeholder="email"
              onChange={(text) => setInvitaionEmail(text.target.value)}
              value={invitationEmail}
            />
            <PrimaryButton
              text="Bjud in"
              onClick={() => {
                sendInvitaion()
              }}
            />
          </div>
        </div>

        <div>
          <h3>Väntande registeringar från Mäklare</h3>
          <SimpleTable
            rows={adminService.agents}
            columns={[
              ['fullName', 'Namn'],
              ['email', 'Email'],
              ['phone', 'Phone'],
              ['status', 'Status'],
            ]}
            getValue={getAgentValue}
          />
        </div>
        <div>
          <h3>Väntelista</h3>
          <SimpleTable
            rows={adminService.emailQueueList}
            columns={[
              ['email', 'Email'],
              ['queue', 'Kö'],
              ['createdAt', 'Skapad'],
              ['removedAt', 'Borttagen'],
            ]}
            getValue={getAgentValue}
          />
        </div>
      </div>
    </PageWrapper>
  )
})
