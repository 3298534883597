import axios, { Axios } from 'axios'
import { makeAutoObservable } from 'mobx'
import { EmailQueueList } from '../../api/auth.api'
import { AuthStore } from '../../stores/auth.store'
import { Agent, AgentStatus } from '../agent-portal/agent.type'

export interface AgentFilterRequest {
  email?: string
  status?: AgentStatus
}

export class AdminApi {
  BASE_URL = process.env.REACT_APP_BUSINESS_BASE_URL
  adminBff: Axios
  authStore: AuthStore
  constructor(authStore: AuthStore) {
    this.authStore = authStore
    this.adminBff = axios.create({
      baseURL: `${this.BASE_URL}/admin-bff`,
      headers: {
        Authorization: `Bearer ${this.authStore.token}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
  }

  async getAgents(filter: AgentFilterRequest) {
    return await this.adminBff
      .post('agents', filter)
      .then((resp) => resp)
      .catch((err) => {
        throw err.response.status
      })
  }
  async getEmailQueueList(filter: Partial<EmailQueueList>) {
    return await this.adminBff
      .post('email-queue-list', filter)
      .then((resp) => resp)
      .catch((err) => {
        throw err.response.status
      })
  }
}

export class AdminService {
  private api: AdminApi
  agents: Agent[] = []
  emailQueueList: EmailQueueList[] = []
  constructor(authStore: AuthStore) {
    makeAutoObservable(this)
    this.api = new AdminApi(authStore)
  }

  async getAgents(filter: AgentFilterRequest) {
    return await this.api
      .getAgents(filter)
      .then((response) => (this.agents = response.data))
      .catch((err) => {
        throw err
      })
  }
  async getEmailQueueList(filter: Partial<EmailQueueList>) {
    return await this.api
      .getEmailQueueList(filter)
      .then((response) => (this.emailQueueList = response.data))
      .catch((err) => {
        throw err
      })
  }
}
