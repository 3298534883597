import { colors } from '../../utils/colors.util'
import './round.icon.scss'
export const IconRoundParHouse = () => (
  <div className="circle-icon">
    <div className="circle-icon-backgrund">
      <svg
        width="45"
        height="35"
        viewBox="0 0 71 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.9161 15.3594H0V41.9963H34.9161V15.3594Z"
          fill={colors.white}
        />
        <path
          d="M70.4766 15.3594H35.5605V41.9963H70.4766V15.3594Z"
          fill={colors.white}
        />
        <path d="M0 14.8136H34.9161L17.4539 0L0 14.8136Z" fill={colors.white} />
        <path
          d="M35.5605 14.8136H70.4766L53.0227 0L35.5605 14.8136Z"
          fill={colors.white}
        />
      </svg>
    </div>
    <p className="karla-normal-shark-14px">Radhus</p>
  </div>
)
