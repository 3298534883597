import { Link } from 'react-router-dom'
import './footer.componet.scss'

const footerData = {
  omHemla: 'OM HEMLA',
  faq: 'FAQ',
  kontakt: 'KONTAKT',
  frMklare: 'FÖR MÄKLARE',
  hemlaAb: 'DOMICI AB',
  streetAddress: '',
  contactEmailAddress: 'info@hemla.se',
  spanText3: 'Stockholm',
  contactPhoneNumber: '',
  policy: 'Integritetspolicy',
  x2022ByHemlaAll: '© 2022 by Hemla. All Rights Reserved. Version 0.1.8',
}

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-row">
          <div className="footer-item">
            <img
              alt="this is text"
              className="hemla-1"
              srcSet={
                '/home-page/hemla@2x.png 250w, /home-page/hemla-1@2x.png 800w'
              }
            />
          </div>
          <div className="footer-right">
            <div className="footer-item">
              <div className="group-2545 rubik-bold-white-16px">
                <Link to="/about-us" className="rubik-bold-white-16px">
                  <div className="om-hemla-1">{footerData.omHemla}</div>
                </Link>
                <Link to="/faq" className="rubik-bold-white-16px">
                  <div className="faq-1">{footerData.faq}</div>
                </Link>
                <Link to="/contact-us" className="rubik-bold-white-16px">
                  <div className="kontakt-1">{footerData.kontakt}</div>
                </Link>
                <Link to="/for-agents">
                  <div className="group-2263">
                    <div className="group-2175">
                      <div className="fr-mklare-1 rubik-bold-white-16px">
                        {footerData.frMklare}
                      </div>
                    </div>
                    <img
                      alt="this is text"
                      className="exit-1"
                      src={'/home-page/exit-1@2x.png'}
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className="footer-item">
              <div className="frame-2553">
                <div className="column">
                  <div className="hemla-ab rubik-bold-white-16px">
                    {footerData.hemlaAb}
                  </div>
                  <span className="karla-normal-white-18px">
                    {footerData.streetAddress}
                  </span>
                  <a
                    className="span-1 karla-normal-white-18px"
                    href={`mailto:${footerData.contactEmailAddress}`}
                  >
                    {footerData.contactEmailAddress}
                  </a>
                  <span className="span-1 karla-normal-white-18px">
                    {footerData.contactPhoneNumber}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="rubik-bold-white-14px">
            <Link to="/policy">
              <p>
                <span className="span-1 rubik-bold-white-14px">
                  {footerData.policy}
                </span>
              </p>
            </Link>
          </div>
          <div>
            <p className="rubik-bold-white-14px">
              {footerData.x2022ByHemlaAll}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
