import { PageWrapper } from '../page-wrapper/page-wrapper'

export const PageNotFound = () => (
  <PageWrapper>
    <>
      <h1>404</h1>
      <h1>Page not found!</h1>
    </>
  </PageWrapper>
)
