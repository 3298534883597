import { Footer } from '../../components/footer/footer.componet'
import { ResponsiveAppBar } from '../../components/menu/menu.component'
import './page-wrapper.scss'
export const PageWrapper = ({
  children,
  showCity = false,
  wideContent = false,
}: {
  children: JSX.Element
  showCity?: boolean
  wideContent?: boolean
}) => {
  return (
    <>
      <div className={wideContent ? '' : 'page-wrap'}>
        <ResponsiveAppBar />
        <div className={wideContent ? '' : 'page-content'}>{children}</div>
        {showCity && (
          <img
            className="staden"
            src="home-page/staden-1@1x.png"
            alt="this is a city"
          />
        )}
      </div>
      <Footer />
    </>
  )
}
