import { IconApartment } from './apartment.icon'
import './round.icon.scss'
export const IconRoundApartment = () => (
  <div className="circle-icon">
    <div className="circle-icon-backgrund">
      <IconApartment color={'#ffffff'} width={22} height={38} />
    </div>
    <p className="karla-normal-shark-14px">Lägenhet</p>
  </div>
)
