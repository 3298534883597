import { Link } from 'react-router-dom'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './sell-info-agents.page.scss'

export const SellInfoAgents = () => {
  return (
    <PageWrapper>
      <>
        <div className="sell-page-agent-content">
          <h1 className="title rubik-bold-white-40px">
            Kom igång helt kostnadsfritt!
          </h1>
          <h4>Förregistrera din profil</h4>
          <div className="behver-du-hjlp-un karla-normal-white-18px">
            Behöver du hjälp?&nbsp;&nbsp;Undrar du något? Eller vill du bara
            snacka? Hör av dig!
          </div>
          <div className="sell-contact-agent-cards">
            <SellInfoCard {...SellInfoStartData} />
            <SellInfoCard {...SellInfoPremium1Data} />
            <SellInfoCard {...SellInfoPremium2Data} />
          </div>
        </div>
        <div className="sell-page-agent-husmodal">
          <PinkMono />
        </div>
      </>
    </PageWrapper>
  )
}

const PinkMono = () => (
  <svg
    width="1312"
    height="498"
    viewBox="0 0 1312 498"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1312 498L1210 103.035L2.17683e-05 3.68581e-06L0 498L1312 498Z" />
  </svg>
)

const SellInfoCard = (info: {
  headline: string
  subheadling: string
  texts: string[]
  linkTo: string
  buttonText: string
  buttonDisabled?: boolean
}) => (
  <div className="sell-info-card">
    <h3>{info.headline}</h3>
    <h4>{info.subheadling}</h4>
    {info.texts.map((text) => (
      <p key={text}>{text}</p>
    ))}
    <p></p>
    <Link to={info.linkTo}>
      <button
        disabled={info.buttonDisabled}
        className="primary-button primary-button-big color-theme-green"
      >
        {info.buttonText}
      </button>
    </Link>
  </div>
)

const SellInfoStartData = {
  headline: 'Start',
  subheadling: '0 kr/månad',
  texts: ['Tillgång till spekulantregister på dina objekt på förmarknaden'],
  linkTo: '/agent-sign-up',
  buttonText: 'Registrera',
}

const SellInfoPremium1Data = {
  headline: 'Premium 1',
  subheadling: '999 kr/månad',
  texts: ['+ Obegränsat med säljleads', '+ Verktyg för att matcha dina leads'],
  linkTo: '/agent-signup',
  buttonText: 'kommer snart',
  buttonDisabled: true,
}
const SellInfoPremium2Data = {
  headline: 'Premium 2',
  subheadling: '1 999 kr/månad',
  texts: [
    '+ Obegränsat med köp- och säljleads',
    '+ Verktyg för att matcha dina leads',
  ],
  linkTo: '/agent-signup',
  buttonText: 'kommer snart',
  buttonDisabled: true,
}
