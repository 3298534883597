import { AdminService } from '../apps/admin-portal/admin.api'
import { AgentPortalService } from '../apps/agent-portal/agent-protal.service'
import { environment } from '../environments/environment'
import { EstateDetailsService } from '../pages/estate-details/estate-details.service'
import { EstateListingsService } from '../pages/estate-listings/estate-listings.service'
import { LoginService } from '../pages/sign-in/sign-in.service'
import { AuthStore } from './auth.store'

export class RootStore {
  env: unknown
  authStore: AuthStore
  estateDetailsService: EstateDetailsService
  estateListingsService: EstateListingsService
  agentPortalService: AgentPortalService
  loginService: LoginService
  adminService: AdminService

  constructor() {
    this.authStore = new AuthStore(this)
    this.estateDetailsService = new EstateDetailsService()
    this.estateListingsService = new EstateListingsService(this.authStore)
    this.agentPortalService = new AgentPortalService()
    this.loginService = new LoginService(this.authStore)
    this.adminService = new AdminService(this.authStore)
    this.env = environment
  }
}
