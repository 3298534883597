import { Slider as MuiSlider } from '@mui/material'
import { useState } from 'react'
import './slider.component.scss'

export const Slider = ({
  onValueChanged = () => {},
  metric = '',
  min = 0,
  max = 100,
  defaultMin = 30,
  defaultMax = 70,
  noUpperLimit = true,
  stepSize = 1,
  minDistance = 10,
  singleValue = false,
}: {
  max?: number
  min?: number
  defaultMin?: number
  defaultMax?: number
  metric?: string
  noUpperLimit?: boolean
  stepSize?: number
  minDistance?: number
  singleValue?: boolean
  onValueChanged?: (value: any) => void
}) => {
  const marks = [
    { value: min, label: `${min} ${metric}` },
    { value: max, label: `${max} ${metric}${noUpperLimit ? '+' : ''}` },
  ]

  const defaultValue = !singleValue ? [defaultMin, defaultMax] : defaultMin

  const [value, setValue] = useState<number | number[]>(defaultValue)

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      setValue(newValue)
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        setValue([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setValue([clamped - minDistance, clamped])
      }
    } else {
      setValue(newValue as number[])
    }
    onValueChanged(value)
  }

  if (singleValue) {
    return (
      <MuiSlider
        valueLabelDisplay="on"
        max={max}
        min={min}
        value={value}
        name={'name'}
        marks={marks}
        onChange={handleChange}
        sx={{ left: 'none' }}
        disableSwap
      />
    )
  }
  return (
    <MuiSlider
      valueLabelDisplay="on"
      max={max}
      min={min}
      value={value}
      name={'name'}
      marks={marks}
      onChange={handleChange}
      step={stepSize}
      sx={{ left: 'none' }}
      disableSwap
    />
  )
}
