import { colors } from '../../utils/colors.util'
import './round.icon.scss'
export const IconRoundTomt = () => (
  <div className="circle-icon">
    <div className="circle-icon-backgrund">
      <svg
        width="36"
        height="36"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 51.6328L3.6737 55.3065H0V51.6328Z" fill={colors.white} />
        <path
          d="M0 41.4766L13.8216 55.3088H5.17509L0 50.1337V41.4766Z"
          fill={colors.white}
        />
        <path
          d="M0 31.3203L23.9801 55.3111H15.3336L0 39.9881V31.3203Z"
          fill={colors.white}
        />
        <path
          d="M6.69783 27.8672L34.1387 55.308H25.4922L0 29.8265V27.8672H6.69783Z"
          fill={colors.white}
        />
        <path
          d="M16.8457 27.8672L44.2972 55.308H35.6507L8.19922 27.8672H16.8457Z"
          fill={colors.white}
        />
        <path
          d="M27.0039 27.8672L54.4447 55.308H45.7983L18.3574 27.8672H27.0039Z"
          fill={colors.white}
        />
        <path d="M55.3183 0V3.88665L51.4316 0H55.3183Z" fill={colors.white} />
        <path
          d="M49.9306 0L55.3186 5.38807V14.0452L41.2734 0H49.9306Z"
          fill={colors.white}
        />
        <path
          d="M39.7715 0L55.3181 15.5466V24.2037L31.125 0H39.7715Z"
          fill={colors.white}
        />
        <path
          d="M29.6126 0L55.3178 25.7052V34.3623L27.877 6.91079V0H29.6126Z"
          fill={colors.white}
        />
        <path
          d="M27.877 8.42188L55.3178 35.8627V44.5198L27.877 17.0683V8.42188Z"
          fill={colors.white}
        />
        <path
          d="M27.877 18.5781L55.3178 46.0083V54.6654L27.877 27.2246V18.5781Z"
          fill={colors.white}
        />
      </svg>
    </div>
    <p className="karla-normal-shark-14px">Tomt</p>
  </div>
)
