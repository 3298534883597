import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PrimaryButton } from '../../components/buttons/primary-button/primary-button.component'
import { useQuery } from '../../utils/useQuery.hook'
import { useRootStore } from '../../utils/useRootStore.hook'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import { LoginService } from '../sign-in/sign-in.service'
import './register-on-invite.page.scss'

export const RegisterOnInvite = () => {
  const { authStore } = useRootStore()
  const query = useQuery()
  const [userEmail, setUserEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [loginService] = useState(() => new LoginService(authStore))
  const history = useHistory()

  useEffect(() => {
    setUserEmail(query.get('email') as string)
  }, [query])

  const registerUser = () => {
    loginService
      .register(userEmail, password, passwordRepeat)
      .then(() => {
        history.push('/buy')
      })
      .catch((err) => {
        setErrorMessage(err.message)
      })
  }
  return (
    <PageWrapper showCity={true}>
      <div className="center-page-root">
        <div className="form-wrapper">
          <h3>Sätt lösenord</h3>
          <div className="input-row">
            <h4>Email</h4>
            <input
              className="text-field"
              type="email"
              value={userEmail}
              disabled={true}
            ></input>
          </div>

          <div className="input-row">
            <h4>Lösenord</h4>
            <input
              className="text-field"
              onFocus={() => setErrorMessage('')}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
          <div className="input-row">
            <h4>Lösenord (upprepa)</h4>
            <input
              className="text-field"
              type="password"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            ></input>
          </div>

          {errorMessage && (
            <div className="input-row">
              <h4>Fel</h4>
              <p>{errorMessage}</p>
            </div>
          )}

          <PrimaryButton
            text="Spara"
            onClick={() => registerUser()}
          ></PrimaryButton>
        </div>
      </div>
    </PageWrapper>
  )
}
