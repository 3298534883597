import { makeAutoObservable } from 'mobx'
import { RootStore } from './root.store'

const LOCAL_STORAGE_TOKEN_KEY = 'domici-token'

export class AuthStore {
  root: RootStore
  userId?: number
  email?: string
  role?: string
  token?: string

  constructor(root: RootStore) {
    makeAutoObservable(this)
    this.root = root
    this.init()
  }

  init() {
    this.setAttributesFromLocalStorage()
  }

  storeToken(token: string, email: string, userId: number, role?: string) {
    this.token = token
    this.email = email
    this.userId = userId
    this.role = role
    this.storeTokenInLocalStorage(
      JSON.stringify({ token, email, userId, role })
    )
  }

  storeTokenInLocalStorage(authObject: any) {
    window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, authObject)
  }

  setAttributesFromLocalStorage() {
    let str = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) ?? undefined
    if (str) {
      let obj = JSON.parse(str)
      if (this.isTokenValid(obj.token)) {
        this.token = obj.token
        this.email = obj.email
        this.userId = obj.userId
        this.role = obj.role
      } else {
        this.token = undefined
        this.email = undefined
        this.userId = undefined
        this.role = undefined
      }
    }
  }

  isTokenValid(token: any) {
    if (token) {
      const expireDate = this.getJWTExpireDate(token)
      if (!expireDate) {
        return false
      }
      if (new Date() <= expireDate) {
        return true
      }
    }
    return false
  }

  get isAuthed() {
    return this.isTokenValid(this.token)
  }

  getJWTExpireDate(jwtToken: string) {
    if (jwtToken) {
      try {
        const [, payload] = jwtToken.split('.')
        const { exp: expires } = JSON.parse(window.atob(payload))
        if (typeof expires === 'number') {
          return new Date(expires * 1000)
        }
      } catch {
        // ignore
      }
    }
    return null
  }

  resetAuth() {
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    this.token = undefined
    this.email = undefined
    this.userId = undefined
    this.role = undefined
  }
}
