import { Skeleton } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Estate } from '../../pages/estate-details/estate-details.service'
import './house-card-mini.component.scss'

export const HouseCardMini = ({ estate }: { estate?: Estate }) => {
  //const isMultiSelectEnabled = isEnabled('mulitSelect_showInterest')

  return (
    <div>
      {estate ? (
        <figure className="prop-box-mini">
          <Link to={`/details/${estate._id}`}>
            <figure className="prop-top-mini">
              <Image {...estate} />
            </figure>
          </Link>
          <div className="prop-box-details">
            <Link to={`/details/${estate._id}`}>
              <h3>{estate.streetAddress}</h3>
              {/* <p>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>{' '}
                  {estate.town}
                </p> */}
            </Link>
            <ul className="listing-details-2">
              <li>
                <p>{`${estate.sqrmLiving} kvm`}</p>
              </li>
              {(estate.price || estate.finalPrice) && (
                <li>
                  <p>{`${estate.price ?? estate.finalPrice} kr`}</p>
                </li>
              )}
            </ul>
          </div>
        </figure>
      ) : (
        <figure className="prop-box-mini skeleton-card">
          <figure className="prop-top-mini">
            <Skeleton variant="rectangular" width={250} height={166} />
          </figure>
          <figure>
            <figure className="listing-title">
              <h3>
                <Skeleton variant="text" />
              </h3>
              <p>
                <i className="fa fa-map-marker" aria-hidden="true"></i>{' '}
                <Skeleton variant="text" />
              </p>
            </figure>
            <Skeleton variant="text" />
          </figure>
        </figure>
      )}
    </div>
  )
}

const Image = (estate: Estate) => {
  const [imageState, setImageState] = useState({
    src: estate.imageUrl ?? '/missing-image.png',
    hasError: estate.imageUrl ? false : true,
    isLoaded: false,
  })

  const handleError = () => {
    if (!imageState.hasError) {
      setImageState({
        src: '/missing-image.png',
        hasError: true,
        isLoaded: true,
      })
    }
  }

  const hanldeOnLoaded = () => {
    setImageState({
      ...imageState,
      isLoaded: true,
    })
  }
  return (
    <div>
      <img
        style={imageState.isLoaded ? {} : { display: 'none' }}
        src={imageState.src}
        alt="img"
        onError={handleError}
        onLoad={() => {
          hanldeOnLoaded()
        }}
      />
      <Skeleton
        sx={!imageState.isLoaded ? {} : { display: 'none' }}
        variant="rectangular"
        width={250}
        height={166}
      />
    </div>
  )
}
