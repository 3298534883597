import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as MapMarker } from '../../assets/icons/Kartnål.svg'
import { ButtomMenu } from '../../components/buttom-menu/buttom-menu'
import { ImageSlider } from '../../components/image-slider/image-slider.component'
import { ShowInterestRequest } from '../../components/show-interest-modal/show-interest-modal.type'
import { useRootStore } from '../../utils/useRootStore.hook'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './estate-details.page.scss'
import { Estate, EstateDetailsService } from './estate-details.service'

export const EstateDetails = observer(() => {
  const { objectId }: { objectId: string } = useParams()
  const { authStore } = useRootStore()
  const [estateService] = React.useState(() => new EstateDetailsService())
  const [estate, setEstates] = React.useState<Estate>()

  React.useEffect(() => {
    estateService.getEstate(objectId)
  }, [estateService, objectId])

  React.useEffect(() => {
    if (estateService.estates?.length) {
      //images = [estateService.estates[0]?.imageUrl];
      setEstates(estateService.estates[0])
    }
  }, [estateService.estates])

  const sendInteresetRequest = (requestObject: ShowInterestRequest) => {
    if (authStore.userId && estate?._id) {
      requestObject.objectId = estate._id
      requestObject.userId = authStore.userId
      estateService.showInterest(requestObject)
    }
  }

  //TODO: tusen avskiljare
  //TODO: tkr/kvm till kr/kvm

  return (
    <PageWrapper>
      <div className="paper">
        {estate ? (
          <div className="prop-page">
            <div className="images">
              <ImageSlider estate={estate} />
              <div className="prop-short-info">
                <ul>
                  {estate?.sqrmLiving && (
                    <li>
                      <p>
                        <CheckMark />
                        {estate?.sqrmLiving} kvm
                      </p>
                    </li>
                  )}
                  {estate?.sqrmLiving && (
                    <li>
                      <p>
                        <CheckMark />
                        {estate?.numberOfRooms} rum
                      </p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="prop-title-row">
              <div>
                <h2 className="dark">{estate.streetAddress}</h2>
                <p>
                  <MapMarker />
                  {estate.town}
                </p>
              </div>
              <div>
                {estate?.price ? (
                  <div className="prop-pricing">
                    <h2 className="price">{estate?.price} SEK</h2>
                    {estate.sqrmLiving && (
                      <h3>
                        {`${Math.round(
                          estate.price / estate.sqrmLiving
                        )} tkr/kvm`}
                      </h3>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="prop-description">
              <ButtomMenu
                originalUrl={estate.url}
                sendInteresetRequest={sendInteresetRequest}
              />
              <h2 className="desc-headline dark">Beskrivning</h2>

              <p>{estate.description}</p>
            </div>
            <div className="prop-details-frame">
              <div className="prop-details">
                <h2 className="desc-headline">Detaljer</h2>
                <ul className="prop-detail-features">
                  {estate?.builtAt && (
                    <li>
                      <p>
                        <b>Byggår:</b> {estate?.builtAt}
                      </p>
                    </li>
                  )}
                  {estate?.numberOfRooms && (
                    <li>
                      <p>
                        <b>Antal rum:</b> {estate.numberOfRooms}
                      </p>
                    </li>
                  )}
                  {estate?.levelInBuilding && (
                    <li>
                      <p>
                        <b>Våning:</b> {estate.levelInBuilding}{' '}
                        {estate?.numberOfLevelsInBuilding
                          ? `av ${estate.numberOfLevelsInBuilding}`
                          : ''}
                      </p>
                    </li>
                  )}
                  {estate?.monthlyFee && (
                    <li>
                      <p>
                        <b>Avgift:</b> {estate.monthlyFee}
                      </p>
                    </li>
                  )}
                </ul>

                {/* <h2 className="desc-headline">Byggnaden</h2>
                <ul>
                  <li>
                    <p>
                      <CheckMark />
                      Portkod
                    </p>
                  </li>
                  <li>
                    <p>
                      <CheckMark />
                      Tvättstuga
                    </p>
                  </li>
                  <li>
                    <p>
                      <CheckMark />
                      Cykelförråd
                    </p>
                  </li>
                  <li>
                    <p>
                      <CheckMark />
                      Fin innergård
                    </p>
                  </li>
                  <li>
                    <p>
                      <CheckMark />
                      Låg avgift
                    </p>
                  </li>
                </ul> */}

                {/* <h3 className="desc-headline">Video</h3>
          <iframe
            title="Manhattan New York Townhouse Residence -- Lifestyle production Group"
            src="https://www.youtube.com/embed/UPBJKppEXoQ?feature=oembed"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            width="100%"
            height="400"
          ></iframe> */}

                {/*TODO don't use key here*/}
              </div>
            </div>

            <h2 className="desc-headline dark">Karta</h2>
            <iframe
              title="gmap"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBOVBcGc4bAAtAL4gPf7CYA1miCOauQ9GY&q=${estate.streetAddress},${estate.town}`}
              width="100%"
              height="400"
              loading="lazy"
            ></iframe>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </PageWrapper>
  )
})

const CheckMark = () => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.46337 10.026L15.0015 2.0427L12.8381 0L5.28995 7.99396L2.03199 4.94797L0 7.12136L5.43349 12.2013L7.46547 10.0279L7.46337 10.026Z"
      fill="white"
    />
  </svg>
)
