import { AuthStore } from '../../stores/auth.store'
import { UserProfileApi } from './user-profile.api'

export class UserProfileService {
  private api: UserProfileApi
  private authStore: AuthStore
  constructor(authStore: AuthStore) {
    this.api = new UserProfileApi(authStore)
    this.authStore = authStore
  }

  async addTracker(request: AddTrackerRequest) {
    return await this.api.addTracker(request)
  }

  async getTrackers() {
    return await this.api.getTrackers().then((response) => {
      const data: Tracker[] = response.data
      return data
    })
  }

  async deleteTracker(trackerId: number) {
    await this.api.deleteTracker(trackerId)
  }

  async getUser(userId: string) {
    return await this.api.getUser(userId)
  }
}

export interface AddTrackerRequest {
  email: string
  buyTracker: CreateTrackerRequest
  sellTracker?: CreateTrackerRequest
}

interface CreateTrackerRequest {
  locations: string[]
  trackerType: 'BUY' | 'SELL'
  requirements?: { [attribute: string]: string | undefined }
}

export interface Tracker {
  trackerType: 'BUY' | 'SELL'
  locations: string[]
  requirements?: TrackerRequirement[]
  trackerId: string
}
export interface TrackerRequirement {
  id: string
  value: string
  property: {
    name: string
  }
}
