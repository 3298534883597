import MenuIcon from '@mui/icons-material/Menu'
import { Divider, Drawer, List, ListItem } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as HemlaLogo } from '../../assets/icons/hemla-logo.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profil.svg'
import { isEnabled } from '../../environments/environment'
import { useRootStore } from '../../utils/useRootStore.hook'
import './menu.component.scss'

type LinkData = { url: string; text: string; role?: string }

const pages: LinkData[] = [
  { text: 'Om Hemla', url: '/about-us' },
  { text: 'Kontakt', url: '/contact-us' },
  { text: 'FAQ', url: '/faq' },
  { text: 'För mäklare', url: '/for-agents' },
]
const settings: LinkData[] = [
  { text: 'Profil', url: '/profile' },
  { text: 'Admin', url: '/admin', role: 'ADMIN' },
  { text: 'Logga ut', url: '/logout' },
]
const logginOptions: LinkData[] = [
  { text: 'Registrera', url: '/' },
  { text: 'Logga in', url: '/login' },
]

export const ResponsiveAppBar = () => {
  const { authStore } = useRootStore()
  const [displayProfileMenu] = useState(isEnabled('menu'))

  const [, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const showLink = (link: LinkData) => {
    return !link?.role || link?.role === authStore.role
  }

  const [isDrawerOpen, setDrawer] = useState(false)
  const toggleDrawer =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setDrawer(!isOpen)
    }

  const LoggedInProfileMenu = () => (
    <>
      <div className="user-email">
        <h4>{authStore.email}</h4>
      </div>
      {settings.filter(showLink).map((setting) => (
        <MenuItem key={setting.url} onClick={handleCloseUserMenu}>
          <Link to={setting.url}>
            <Typography className="rubik-bold-shark-16px" textAlign="center">
              {setting.text}
            </Typography>
          </Link>
        </MenuItem>
      ))}
    </>
  )

  const MobileLoggedInProfileMenu = () => (
    <>
      <div className="user-email">
        <h4>{authStore.email}</h4>
      </div>
      {settings.filter(showLink).map((page) => (
        <ListItem button key={page.url} onClick={toggleDrawer(isDrawerOpen)}>
          <Link to={page.url}>
            <Typography textAlign="center">{page.text}</Typography>
          </Link>
        </ListItem>
      ))}
    </>
  )

  const ProfileMenu = () => (
    <Menu
      sx={{ mt: '45px', textDecoration: 'none' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {authStore.isAuthed ? (
        <LoggedInProfileMenu />
      ) : (
        logginOptions.map((setting) => (
          <MenuItem key={setting.url} onClick={handleCloseUserMenu}>
            <Link to={setting.url}>
              <Typography className="rubik-bold-shark-16px" textAlign="center">
                {setting.text}
              </Typography>
            </Link>
          </MenuItem>
        ))
      )}
    </Menu>
  )

  return (
    <AppBar position="static" color="transparent" className="responsive-appbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              textDecoration: 'none',
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(isDrawerOpen)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              className="drawer"
              anchor="left"
              open={isDrawerOpen}
              onClose={toggleDrawer(isDrawerOpen)}
            >
              <List>
                {pages.map((page) => (
                  <ListItem
                    button
                    key={page.url}
                    sx={{ textDecoration: 'none' }}
                    onClick={toggleDrawer(isDrawerOpen)}
                  >
                    <Link to={page.url}>
                      <Typography textAlign="center">{page.text}</Typography>
                    </Link>
                  </ListItem>
                ))}
                <Divider />
              </List>
              <List>
                {authStore.isAuthed && displayProfileMenu ? (
                  <MobileLoggedInProfileMenu />
                ) : (
                  displayProfileMenu &&
                  logginOptions.map((page: LinkData) => (
                    <ListItem
                      button
                      key={page.url}
                      onClick={toggleDrawer(isDrawerOpen)}
                    >
                      <Link to={page.url}>
                        <Typography textAlign="center">{page.text}</Typography>
                      </Link>
                    </ListItem>
                  ))
                )}
              </List>
            </Drawer>
          </Box>

          <Link to="/">
            <HemlaLogo />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-end',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {pages.map((page) => (
              <Link to={page.url}>
                <Button
                  key={page.url}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    textDecoration: 'none',
                  }}
                >
                  {page.text}
                </Button>
              </Link>
            ))}
          </Box>

          {displayProfileMenu && (
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="Open settings">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  className="profile-icon"
                >
                  <ProfileIcon />
                </IconButton>
              </Tooltip>
              <ProfileMenu />
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
