import { observer } from 'mobx-react-lite'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { ReactComponent as MonoSvg } from '../../assets/monolith/monolith.svg'
import { Estate } from '../../pages/estate-details/estate-details.service'
import { RoundArrowButton } from '../buttons/round-arrow-button/round-arrow-button.component'
import { HouseCardMini } from '../house-card-mini/house-card-mini.component'
import './carousel-list.component.scss'

const NextButton = ({
  onClick: onClickFn,
}: {
  className?: string
  style?: any
  onClick?: Function
}) => {
  if (onClickFn) {
    return (
      <RoundArrowButton
        className="slick-next"
        onClick={onClickFn}
      ></RoundArrowButton>
    )
  }
  return <></>
}

const PrevButton = ({
  onClick: onClickFn,
}: {
  className?: string
  style?: any
  onClick?: Function
}) => {
  if (onClickFn) {
    return (
      <RoundArrowButton
        reversed={true}
        className="slick-prev"
        onClick={onClickFn}
      ></RoundArrowButton>
    )
  }
  return <></>
}

export type LazyLoadTypes = 'ondemand' | 'progressive'

export const CarouselList = observer(
  ({ estates, settings }: { estates?: Estate[]; settings?: Settings }) => {
    const applyedSettings = {
      ...defaultSettings,
      ...settings,
    }

    if (!estates || !estates.length) {
      return <></>
    }

    return (
      <div className="match-list">
        <Slider {...applyedSettings}>
          {estates?.map((estate) => (
            <HouseCardMini estate={estate} key={estate.url} />
          ))}
        </Slider>
        <MonoSvg className="monolith" />
      </div>
    )
  }
)

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3, //TODO calculate how many "pages" there is
  rows: 3,
  lazyLoad: 'ondemand' as LazyLoadTypes, // seems to be a bug in react-slick
  responsive: [
    // {
    //   breakpoint: 1024,
    //   settings: {
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     rows: 3,
    //     infinite: true,
    //     dots: true,
    //   },
    // },
    {
      breakpoint: 600,
      settings: {
        rows: 1,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        rows: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],

  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
}
