import { useState } from 'react'
import { PrimaryButton } from '../buttons/primary-button/primary-button.component'
import './contact-us-card.component.scss'

export const ContactUsCard = () => {
  const [displayContactInfo, setDisplayContactInfo] = useState(false)

  return (
    <div className="frame-contact-us">
      <h3>Har du några frågor?</h3>
      <h3>Vi hjälper dig!</h3>

      {displayContactInfo && (
        <p>
          <a href="mailto:info@hemla.se">info@hemla.se</a>
        </p>
      )}
      {!displayContactInfo && (
        <PrimaryButton
          color="orange"
          size="big"
          text="Kontakta oss"
          onClick={() => setDisplayContactInfo(true)}
        />
      )}
    </div>
  )
}
