export const colors = {
  background: "#e5e5e5",
  kartong: "#e5d0ae",
  bubbelgum: "#df84ad",
  biljardbord: "#0c725d",
  biljardbordHover: "#0a5f4d",
  biljardbordClicked: "#074639",
  biljardbordDisabled: "#0c725d",
  persimon: "#f6682f",
  persimonHover: "#db5c29",
  persimonClicked: "#bd5125",
  persimonDisabled: "#f6682f",
  almostBlack: "#292929",
  oatly: "#f6f5ed",
  error: "#ae0000",
  white: "#ffffff",
};
