import { useState } from 'react'
import { PrimaryButton } from '../../../components/buttons/primary-button/primary-button.component'
import { useRootStore } from '../../../utils/useRootStore.hook'
import { PageWrapper } from '../../page-wrapper/page-wrapper'
import './agent-sign-up.page.scss'

export const AgentSignUp = () => {
  const { loginService } = useRootStore()
  const [errorText, setErrorText] = useState<string | undefined>()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState<string>()
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [companyName, setCompanyName] = useState<string>()
  // const [password, setPassword] = useState<string>()
  // const [repeatPassword, setRepeatPassword] = useState<string>()

  const submitSignup = () => {
    setErrorText(undefined)
    if (!email.includes('@')) {
      setErrorText('Skriv in din mailadress')
      return
    }

    // if (password !== repeatPassword) {
    //   setErrorText('Lössenordet matchar inte')
    //   return
    // }

    if (!firstName || !lastName || !companyName || !email) {
      setErrorText('Skriv in alla fält')
      return
    }

    const request = {
      firstName,
      lastName,
      email,
      companyName,
      phone,
    }
    loginService
      .agentSignup(request)
      .then((resp) => {
        setErrorText(
          'Tack för anmälan! Vi kommer innom kort höra av oss till er!'
        )
      })
      .catch((err) => {
        if (err.statusCode === 400) {
          setErrorText(err.message)
        } else {
          setErrorText('Fel uppstod')
        }
      })
  }

  return (
    <PageWrapper showCity={true}>
      <div className="buy-page">
        <div className="description-container">
          <h2 className="kpbevaka-med-hemla rubik-bold-watercourse-32px">
            Förregistrera din mäklarprofil på Hemla
          </h2>
          <p className="karla-normal-shark-18px">
            Snart lanseras Hemlas förmarknadstjänst för mäklare.
            <br />
            Genom att förregistrera dig innan lansering får du en gratis
            testperiod ( 3 mån) av abonnemangen Premium 1 & 2 när de går live.
            <br />
            Vi ser fram emot att hjälpa dig matcha köpare & säljare på
            marknaden!
          </p>
        </div>

        <div className="form-wrapper email-sign-up-form">
          <div className="form-row margin-bottom">
            <h4>Förnamn</h4>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="text-field"
              type="text"
              placeholder="Skriv in ditt förnamn"
            ></input>
          </div>
          <div className="form-row margin-bottom">
            <h4>Efternamn</h4>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="text-field"
              type="text"
              placeholder="Skriv in ditt efternamn"
            ></input>
          </div>
          <div className="form-row margin-bottom">
            <h4>Mailadress</h4>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="text-field"
              type="email"
              placeholder="Skriv in din mailadress"
            ></input>
          </div>
          <div className="form-row margin-bottom">
            <h4>Telefon</h4>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="text-field"
              type="text"
              placeholder="Skriv in ditt telefonnummer"
            ></input>
          </div>
          <div className="form-row margin-bottom">
            <h4>Företag</h4>
            <input
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="text-field"
              type="text"
              placeholder="Ange företag du jobbar på"
            ></input>
          </div>
          {/* <div className="form-row margin-bottom">
            <h4>Lösenord</h4>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="text-field"
              type="password"
              placeholder="Skriv in ett lösenord"
            ></input>
          </div>
          <div className="form-row margin-bottom">
            <h4>Repetera Lösenord</h4>
            <input
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              className="text-field"
              type="password"
              placeholder="Repetera lösenord"
            ></input>
          </div> */}

          {errorText && <p>{errorText}</p>}
          <div className="footer-row">
            <PrimaryButton
              text={'Förregistrera profil'}
              size="small"
              color="green"
              onClick={submitSignup}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}
