import React from 'react'
import { PrimaryButton } from '../buttons/primary-button/primary-button.component'
import './show-interest-modal.modal.scss'
import { AccessToPropertyTimeFrame } from './show-interest-modal.type'

export const ShowInterestModal = ({
  hideModal,
  closeModal,
  sendInteresetRequest,
}: {
  hideModal: boolean
  closeModal: Function
  sendInteresetRequest: Function
}) => {
  const [fullName, setFullName] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [zipCode, setZipCode] = React.useState('')
  const [phone, setPhone] = React.useState('')
  // const [canPlaceOfferBeforeShowing, setCanPlaceOfferBeforeShowing] =
  //   React.useState(true);
  // const [customerJourney, setCustomerJourney] = React.useState("");
  const [wantedAccessWhen, setWantedAccessWhen] = React.useState('')

  const prepareSendRequest = () => {
    const requestObejct = {
      fullName,
      currentAddress: address,
      currentZipCode: zipCode,
      phone,
      // canPlaceOfferBeforeShowing,
      // customerJourney,
      wantedAccessWhen,
    }
    sendInteresetRequest(requestObejct)
  }

  return (
    <div className="modal" hidden={hideModal}>
      <div className="modal-content">
        <span className="close" onClick={() => closeModal()}>
          &times;
        </span>
        <form className="p-3">
          <div className="form-group">
            <label htmlFor="name">För- och efternamn</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="För- och efternamn"
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="adress">Adress</label>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Address"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="zipCode">Postkod</label>
            <input
              type="text"
              className="form-control"
              id="zipCode"
              placeholder="Postkod"
              value={zipCode}
              onChange={(event) => setZipCode(event.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="otherField">
              Kan du tänka dig att lägga ett bud innan visning?
            </label>
            <select className="form-control" id="otherField">
              <option>Ja</option>
              <option>Ja, om jag får en förhandsvisning</option>
              <option>Nej</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="otherField">
              Var på bostadsresan befinner du dig idag?
            </label>
            <select className="form-control" id="otherField">
              <option>Jag har sålt min bostad och är redo att köpa</option>
              <option>Jag behöver sälja min bostad innan jag köper</option>
              <option>
                Jag är inte beroende av att sälja min bostad för att köpa
              </option>
            </select>
          </div>

          {/* <div className="form-group">
            <label htmlFor="seeAnotherField">
              Do you have a property today that you might want to sell?
            </label>
            <select
              className="form-control"
              id="seeAnotherField"
              value={hasProperty}
              onChange={(event) => setHasProperty(event.target.value)}
            >
              <option value="nej">Nej</option>
              <option value="ja">Ja</option>
            </select>
          </div>

          <div
            className="form-group"
            id="otherFieldDiv"
            hidden={hasProperty !== "ja"}
          >
            <label htmlFor="otherField">
              which of the following matches you?
            </label>
            <select className="form-control" id="otherField">
              <option>I want to sell before I buy a new property</option>
              <option> I want to buy before I sell my current property</option>
            </select>
          </div> */}

          <div className="form-group">
            <label>När vill du tillträda bostaden?</label>
            <select
              className="form-control"
              id="seeAnotherField"
              onSelect={(e) => setWantedAccessWhen(e.currentTarget.value)}
            >
              <option value={AccessToPropertyTimeFrame.ASAP}>Snarast</option>
              <option value={AccessToPropertyTimeFrame.WITHIN_3_MONTHS}>
                Inom 3 månader
              </option>
              <option value={AccessToPropertyTimeFrame.WITHIN_6_MONTHS}>
                Inom 6 månader
              </option>
              <option value={AccessToPropertyTimeFrame.FLEXIBLE}>
                Jag är flexibel och kan anpassa mig efter säljarens önskemål
              </option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="phone">
              Om du föredrar att bli kontaktat av mäklare via telefon skriv in
              ditt nummer nedan
            </label>
            <input
              type="text"
              className="form-control"
              id="phone"
              placeholder="Telefonnummer"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>

          <PrimaryButton
            text="Skicka intresseanmälan"
            color={'green'}
            disabled={fullName.length === 0}
            onClick={prepareSendRequest}
          />
        </form>
      </div>
    </div>
  )
}
