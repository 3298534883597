import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

export const SimpleTable = ({
  rows,
  columns,
  getValue,
}: {
  rows: unknown[]
  columns: [string, string][]
  getValue: (
    row: any,
    column: [string, string]
  ) => string | number | Date | null
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((pair) => (
              <TableCell>{pair[1]}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {columns.map((pair) => (
                <TableCell align="right">{getValue(row, pair)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
