import { Checkbox } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  AddTrackerRequest,
  UserProfileService,
} from '../../../pages/user-profile/user-profile.service'
import { colors } from '../../../utils/colors.util'
import { useRootStore } from '../../../utils/useRootStore.hook'
import { PrimaryButton } from '../../buttons/primary-button/primary-button.component'
import { RoundArrowButton } from '../../buttons/round-arrow-button/round-arrow-button.component'
import { IconRoundApartment } from '../../icons/apartment-round.icon'
import { IconRoundCabin } from '../../icons/cabin-round.icon'
import { IconRoundHouse } from '../../icons/house-round.icon'
import { IconRoundParHouse } from '../../icons/par-house-round.icon'
import { IconRoundTomt } from '../../icons/tomt-round.icon'
import { AddressSearch } from '../../inputs/address-search/addreas-search-input.component'
import { RadioButtonsGroup } from '../../inputs/radio-button-group/radio-button-group.component'
import { Slider } from '../../inputs/slider/slider.component'
import { ProgressBar } from '../../progress-bar/progress-bar.container'
import {
  EstateTypes,
  IntervalType,
  LivingSituationType,
} from './tracker.entities'
import './update-form.component.scss'

export const UpdateForm = () => {
  const [step, setStep] = useState(1)

  const { authStore } = useRootStore()

  const [userProfileService] = useState(() => new UserProfileService(authStore))
  const addTrackers = (request: AddTrackerRequest) => {
    userProfileService.addTracker(request)
    setIsUserOwningInformationKnown(true) //hack to make it to not show step31 again
  }
  const [addresses, setAddresses] = useState<string[]>([])
  const [sqrm, setSqrm] = useState<number[]>([50, 100])
  const [rooms, setRooms] = useState<number[]>([2, 5])
  const [price, setPrice] = useState<number[]>([2, 3.6])
  const [interval, setInterval] = useState<IntervalType>('DAILY')
  const [livingSituation, setLivingSituation] = useState<LivingSituationType>()
  const [estateTypes, setEstateTypes] = useState<string>()

  const [sellAddress, setSellAddress] = useState<string[]>([])
  const [sellSqrm, setSellSqrm] = useState<number>(75)
  const [sellRooms, setSellRooms] = useState<number>(3)
  const [sellInterval, setSellInterval] = useState<IntervalType>('MONTHLY')
  const [sellEstateTypes, setSellEstateTypes] = useState<string>()
  const [skipSellTracking, setSkipSellTracking] = useState(false)

  const [isUserOwningInformationKnown, setIsUserOwningInformationKnown] =
    useState(true)

  const [hasSellTracker, setHasSellTracker] = useState(true)

  useEffect(() => {
    if (authStore.userId) {
      userProfileService.getUser(`${authStore.userId}`).then((resp) => {
        setIsUserOwningInformationKnown(resp.data.livingSituation)
        setHasSellTracker(resp.data?.hasSellTracker ?? false)
      })
    }
  }, [userProfileService, authStore])

  const submitAddTrackerRequest = () => {
    const request: AddTrackerRequest = {
      email: authStore.email ?? '',
      buyTracker: {
        locations: addresses,
        trackerType: 'BUY',
        requirements: {
          minSqrm: sqrm?.at(0) ? `${sqrm?.at(0)}` : undefined, //ugly converstion to string | undefined
          maxSqrm: sqrm?.at(1) ? `${sqrm?.at(1)}` : undefined,
          minRoom: rooms?.at(0) ? `${rooms?.at(0)}` : undefined,
          maxRoom: rooms?.at(1) ? `${rooms?.at(1)}` : undefined,
          minPrice: price?.at(0) ? `${price?.at(0)}` : undefined,
          maxPrice: price?.at(1) ? `${price?.at(1)}` : undefined,
          estateType: estateTypes?.length ? `${estateTypes}` : undefined,
          interval: interval,
          livingSituation: livingSituation,
        },
      },
    }
    if (!skipSellTracking) {
      request.sellTracker = {
        locations: sellAddress,
        trackerType: 'SELL',
        requirements: {
          sqrm: `${sellSqrm}`, //ugly converstion to string | undefined
          room: `${sellRooms}`,
          interval: sellInterval,
          estateType: sellEstateTypes?.length
            ? `${sellEstateTypes}`
            : undefined,
        },
      }
    }
    addTrackers(request)
  }

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    estateType: EstateTypes
  ) => {
    //ATM the BF only can handle one estateType at the time
    if (event.target.checked) {
      setEstateTypes(estateType)
    } else {
      setEstateTypes(undefined)
    }
    // if (event.target.checked) {
    //   setEstateTypes([...estateTypes, estateType])
    // } else {
    //   const rest = estateTypes.filter((e) => e !== estateType)
    //   setEstateTypes(rest)
    // }
  }
  const step1 = () => (
    <div>
      <div className="input-row">
        <h4>Var vill du bo?</h4>
        <AddressSearch addresses={addresses} setAddresses={setAddresses} />
      </div>

      <div className="input-row">
        <h4>Hur vill du bo?</h4>
        <Checkbox
          checked={estateTypes === 'APARTMENT'}
          onChange={(event) => handleCheckboxChange(event, 'APARTMENT')}
          checkedIcon={<IconRoundApartment />}
          icon={<IconRoundApartment />}
        />
        <Checkbox
          checked={estateTypes === 'HOUSE'}
          onChange={(event) => handleCheckboxChange(event, 'HOUSE')}
          checkedIcon={<IconRoundHouse />}
          icon={<IconRoundHouse />}
        />
        <Checkbox
          checked={estateTypes === 'PAR_HOUSE'}
          onChange={(event) => handleCheckboxChange(event, 'PAR_HOUSE')}
          checkedIcon={<IconRoundParHouse />}
          icon={<IconRoundParHouse />}
        />
        <Checkbox
          checked={estateTypes === 'TOMT'}
          onChange={(event) => handleCheckboxChange(event, 'TOMT')}
          checkedIcon={<IconRoundTomt />}
          icon={<IconRoundTomt />}
        />
        <Checkbox
          checked={estateTypes === 'CABIN'}
          onChange={(event) => handleCheckboxChange(event, 'CABIN')}
          checkedIcon={<IconRoundCabin />}
          icon={<IconRoundCabin />}
        />
      </div>
    </div>
  )

  const nextButton = () => {
    const steps = stepComponents.length
    if (step < steps) {
      if (step === steps - 1) {
        return (
          <PrimaryButton
            text={'Bevaka'}
            size="small"
            color="green"
            onClick={() => {
              submitAddTrackerRequest()
              setStep(step + 1)
            }}
          />
        )
      }
      return <RoundArrowButton onClick={() => setStep(step + 1)} />
    }
    return <></>
  }

  const step2 = () => (
    <div className="form-container-content">
      <div className="input-row">
        <h4>yta</h4>
        <Slider
          min={10}
          max={300}
          noUpperLimit={true}
          metric={'kvm'}
          defaultMin={50}
          defaultMax={100}
          minDistance={3}
          onValueChanged={setSqrm}
        />
      </div>

      <div className="input-row">
        <h4>antal rum</h4>
        <Slider
          min={1}
          max={10}
          defaultMin={2}
          defaultMax={5}
          minDistance={1}
          noUpperLimit={true}
          onValueChanged={setRooms}
        />
      </div>

      <div className="input-row">
        <h4>Pris</h4>
        <Slider
          min={1}
          max={10}
          defaultMin={2}
          defaultMax={3.6}
          noUpperLimit={true}
          stepSize={0.01}
          minDistance={0.5}
          metric="miljoner"
          onValueChanged={setPrice}
        />
      </div>
    </div>
  )

  const step3 = () => (
    <div className="form-container-content">
      <div className="input-row">
        <h4>Hur ofta vill du ha köpbevakningsmail?</h4>
        <RadioButtonsGroup
          onChange={setInterval}
          options={[
            { label: 'Dagligen', value: 'DAILY', isDefault: true },
            { label: 'Veckovis', value: 'WEEKLY' },
            { label: 'Månadsvis', value: 'MONTHLY' },
          ]}
        />
      </div>
      {!hasSellTracker && !skipSellTracking && (
        <div className="input-row">
          <h4>Hur ofta vill du ha säljbevakningsmail?</h4>
          <RadioButtonsGroup
            onChange={setSellInterval}
            options={[
              { label: 'Månadsvis', value: 'MONTHLY', isDefault: true },
              { label: 'Kvartalsvis', value: 'QUARTERLY', isDefault: true },
            ]}
          />
        </div>
      )}
    </div>
  )
  const step31 = () => (
    <div className="form-container-content">
      <div className="row">
        <h4>Hur ser din boendesituation ut idag?</h4>
      </div>
      <div className="row">
        <RadioButtonsGroup
          onChange={setLivingSituation}
          options={[
            { label: 'Jag äger min bostad', value: 'OWNS' },
            { label: 'Jag hyr min bostad', value: 'RENTS' },
            { label: 'Annat', value: 'UNKOWN', isDefault: true },
          ]}
        />
      </div>
    </div>
  )

  const step4 = () => (
    <div className="form-container-content">
      <div className="row">
        <h1>Tack!</h1>
      </div>
      <div className="row">
        <p>Nu börjar vi leta efter din drömbostad åt dig!</p>
      </div>
      <div className="row"></div>

      <div className="row">
        <PrimaryButton
          text="Lägg till flera bevakningar"
          color="inversed-green"
          onClick={() => {
            setStep(1)
          }}
        />
      </div>
    </div>
  )

  const SellTrackerStep = () => {
    const handleCheckboxChange = (
      event: ChangeEvent<HTMLInputElement>,
      estateType: EstateTypes
    ) => {
      //ATM the BF only can handle one estateType at the time
      if (event.target.checked) {
        setSellEstateTypes(estateType)
      } else {
        setSellEstateTypes(undefined)
      }
    }

    return (
      <div>
        <div className="input-row">
          <h4>Din nuvarande adress?</h4>
          <AddressSearch
            addresses={sellAddress}
            setAddresses={setSellAddress}
            allowOnlyOneAddress={true}
          />
        </div>

        <div className="input-row">
          <h4>Hur bor du?</h4>
          <Checkbox
            checked={sellEstateTypes === 'APARTMENT'}
            onChange={(event) => handleCheckboxChange(event, 'APARTMENT')}
            checkedIcon={<IconRoundApartment />}
            icon={<IconRoundApartment />}
          />
          <Checkbox
            checked={sellEstateTypes === 'HOUSE'}
            onChange={(event) => handleCheckboxChange(event, 'HOUSE')}
            checkedIcon={<IconRoundHouse />}
            icon={<IconRoundHouse />}
          />
          <Checkbox
            checked={sellEstateTypes === 'PAR_HOUSE'}
            onChange={(event) => handleCheckboxChange(event, 'PAR_HOUSE')}
            checkedIcon={<IconRoundParHouse />}
            icon={<IconRoundParHouse />}
          />
          <Checkbox
            checked={sellEstateTypes === 'TOMT'}
            onChange={(event) => handleCheckboxChange(event, 'TOMT')}
            checkedIcon={<IconRoundTomt />}
            icon={<IconRoundTomt />}
          />
          <Checkbox
            checked={sellEstateTypes === 'CABIN'}
            onChange={(event) => handleCheckboxChange(event, 'CABIN')}
            checkedIcon={<IconRoundCabin />}
            icon={<IconRoundCabin />}
          />
        </div>

        <div className="form-container-content">
          <div className="input-row">
            <h4>Hur stor bostad har du idag?</h4>
            <Slider
              min={10}
              max={300}
              defaultMin={70}
              noUpperLimit={true}
              metric={'kvm'}
              onValueChanged={setSellSqrm}
              singleValue={true}
            />
          </div>

          <div className="input-row">
            <h4>Hur många rum har du?</h4>
            <Slider
              min={1}
              max={10}
              defaultMin={3}
              noUpperLimit={true}
              onValueChanged={setSellRooms}
              singleValue={true}
            />
          </div>
          <div className="reverse-row">
            <button
              className="secondary-button color-theme-inversed-green"
              onClick={() => {
                setSkipSellTracking(true)
                setStep(step + 1)
              }}
            >
              Hoppa över detta steg
            </button>
          </div>
        </div>
      </div>
    )
  }

  let stepComponents = [step1, step2]
  if (!isUserOwningInformationKnown) {
    stepComponents.push(step31)
  }
  if (!hasSellTracker) {
    stepComponents.push(SellTrackerStep)
  }
  stepComponents.push(...[step3, step4])
  return (
    <div className="form-container">
      <div className="tab-container">
        <div className="tab">
          <p>Bevakning</p>
        </div>
        <div className="add-tab color-theme-orange">
          <svg
            width="15"
            height="15"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.5 0H21.5V21.5H0V27.5H21.5V49H27.5V27.5H49V21.5H27.5V0Z"
              fill={colors.white}
            />
          </svg>
        </div>
      </div>
      <div className="form-base">
        <div className="row">
          <ProgressBar steps={stepComponents.length} step={step} />
        </div>

        {stepComponents.at(step - 1)?.call({})}

        <div className="footer-row">
          {step > 1 && step < stepComponents.length ? (
            <RoundArrowButton
              reversed={true}
              inversed={true}
              colorTheme="inversed-green"
              onClick={() => setStep(step - 1)}
            />
          ) : (
            <></>
          )}
          {nextButton()}
        </div>
      </div>
    </div>
  )
}
