// A custom hook that builds on useLocation to parse

import React from 'react'
import { useLocation } from 'react-router-dom'

// the query string for you.
/*
Example: 
let query = useQuery();
query.get("name") //Gets name of example.se/name?victor
*/
export function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
