export const IconApartment = ({
  color = "#0C725D",
  width = 33,
  height = 60,
}: {
  color?: string;
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 33 60`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V59.3684H32.346V0H0ZM13.4068 50.0978H6.32828V43.0193H13.4068V50.0978ZM13.4068 37.8653H6.32828V30.7868H13.4068V37.8653ZM13.4068 25.6328H6.32828V18.5543H13.4068V25.6328ZM13.4068 13.4003H6.32828V6.32828H13.4068V13.4003ZM26.0047 50.0978H18.9392V43.0193H26.0047V50.0978ZM26.0047 37.8653H18.9392V30.7868H26.0047V37.8653ZM26.0047 25.6328H18.9392V18.5543H26.0047V25.6328ZM26.0047 13.4003H18.9392V6.32828H26.0047V13.4003Z"
      fill={color}
    />
  </svg>
);
