import { UpdateForm } from '../../components/forms/update-form/update-form.component'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './buy.page.responsive.scss'

export const BuyPage = () => {
  return (
    <PageWrapper showCity={true}>
      <div className="buy-page">
        <div className="description-container">
          <h2 className="kpbevaka-med-hemla rubik-bold-watercourse-32px">
            Köpbevaka med Hemla
          </h2>
          <p className="karla-normal-shark-18px">
            Här beskriver vi alla fördelar med att köpbevaka med Hemla. Vi är
            oberoende och erbjuder det bästa verkyget på marknaden. Bli endast
            matchad på objekt du fakiskt vill ha.
          </p>
        </div>

        <UpdateForm />
      </div>
    </PageWrapper>
  )
}
