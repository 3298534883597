import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { GMapsApi } from '../../../api/gmaps.api'

//TODO add debouncer and error handling!
export const AddressSearch = observer(
  ({
    addresses,
    setAddresses,
    allowOnlyOneAddress = false,
  }: {
    addresses: string[]
    setAddresses: (arg0: string[]) => void
    allowOnlyOneAddress?: boolean
  }) => {
    const [gmapsApi] = useState(() => new GMapsApi())
    const [searchText, setSearchText] = useState('')
    const [placeSuggestions, setPlaceSuggestions] = useState<string[]>([])

    useEffect(() => {
      if (searchText.length) {
        gmapsApi
          .queryGMaps(searchText)
          .then((result) => setPlaceSuggestions(result.data))
      }
    }, [gmapsApi, searchText])

    function handleOnChange(value: string): void {
      if (placeSuggestions.includes(value)) {
        setAddresses([...addresses, value])
        setSearchText('')
      } else {
        setSearchText(value)
      }
    }

    function removeAddress(address: string): void {
      const remaining = addresses.filter((a) => a !== address)
      setAddresses([...remaining])
    }

    return (
      <>
        <input
          type="text"
          name="keyword_search"
          className="text-field karla-normal-shark-20px"
          placeholder="Skriv in address eller område"
          onChange={(text) => handleOnChange(text.target.value)}
          value={searchText}
          list="suggestions"
          hidden={allowOnlyOneAddress && addresses.length > 0}
        />
        <datalist id="suggestions">
          {placeSuggestions.length &&
            placeSuggestions.map((suggestion) => (
              <option value={suggestion} key={suggestion} />
            ))}
        </datalist>
        <div className="tags">
          {addresses.map((address) => (
            <button className="tag">
              <p key={address}>{address}</p>
              <i onClick={() => removeAddress(address)}>
                <FontAwesomeIcon icon={faTimes} color={'white'} size="xs" />
              </i>
            </button>
          ))}
        </div>
      </>
    )
  }
)
