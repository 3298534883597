import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PrimaryButton } from '../../components/buttons/primary-button/primary-button.component'
import { useRootStore } from '../../utils/useRootStore.hook'
import { PageWrapper } from '../page-wrapper/page-wrapper'
import './sign-in.page.scss'
import { LoginService } from './sign-in.service'

export const SignIn = () => {
  const { authStore } = useRootStore()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errorText, setErrorText] = React.useState('')
  const [loginService] = React.useState(() => new LoginService(authStore))
  const [isLoading, setIsLoading] = useState(false)
  let location: any = useLocation()

  let from = location.state?.from?.pathname || '/'

  const login = () => {
    setIsLoading(true)
    loginService
      .login(email, password)
      .then((resp) => {
        setIsLoading(false)
        setErrorText('Inloggad!')

        window.location.replace(from)
      })
      .catch((err) => {
        setIsLoading(false)
        if (err === 400) {
          setErrorText('Inkorrekta inloggingsuppgifter')
        } else {
          setErrorText('Fel uppstod')
        }
      })
  }

  return (
    <PageWrapper showCity={true}>
      <div className="two-column-page">
        <div className="description-container">
          <h2>Logga in</h2>
          <p>Ange dina inloggingsuppgifter</p>
        </div>
        <div className="form-wrapper form-container">
          <h3>Inloggning</h3>
          <div className="input-row">
            <h4>Emailadress</h4>
            <input
              className="text-field karla-normal-shark-20px"
              type="email"
              placeholder="e-post"
              onChange={(event) => setEmail(event.target.value)}
            ></input>
          </div>
          <div className="input-row">
            <h4>Lösenord</h4>
            <input
              className="text-field karla-normal-shark-20px"
              type="password"
              placeholder="lösenord"
              onChange={(event) => setPassword(event.target.value)}
            ></input>
          </div>
          <div>{errorText}</div>
          <div className="reverse-row">
            {isLoading ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <PrimaryButton text="Logga in" onClick={() => login()} />
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}
